import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { SimulatorDataService } from 'src/simulator-data-service/simulator-data.service';
import { debounceTime } from 'rxjs/operators';
import { ErrorComponentSource } from '../shared/models/errorComponentSource';
import { GoogleAnalyticsService } from '../shared/google-analytics-service/google-analytics.service';
import { LabelType} from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-pigment-info-card',
  templateUrl: './pigment-info-card.component.html',
  styleUrls: ['./pigment-info-card.component.scss']
})
export class PigmentInfoCardComponent implements OnInit {
  private valueChanged$ = new Subject<any>();
  value: number = 0.0;
  step: number = 0.001;
  pigments: any = [];
  constOrderedPigments: any = [];
  pigmentsfixed: any = [];
  isAdjustmentSaved = true;
  isLoadingError = false;
  pigmentPositionForGA = 0;
  pigmentsCountForGA = 0;
  pigmentValueDecimalSize = 3;
  selectedHit = 0;

  constructor(
    private simulatorDataService: SimulatorDataService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.simulatorDataService.initialLoad = true;
    this.selectedHit = this.simulatorDataService.selectedHitRecommendation;
    this.valueChanged$.pipe(
      debounceTime(2000),
    ).subscribe(_ => {
        this.googleAnalyticsService.pushData(
          'pig_slider_adj_' + this.pigmentPositionForGA + '-of-' + this.pigmentsCountForGA,
          'Adjust Slider ' + this.pigmentPositionForGA + ' of ' + this.pigmentsCountForGA
        );
      });

    this.simulatorDataService.formulaResponse$.subscribe(pigmentsList => {
      if (this.isLoadingError) {
        this.simulatorDataService.initialLoad = true;
        this.isLoadingError = false;
        this.resetall();
      }
      this.loadPigments(pigmentsList);
    });

    this.simulatorDataService.recommendationV2Response$.subscribe(recommendationV2Response => {
      this.selectedHit = this.simulatorDataService.selectedHitRecommendation;
      this.updatePigments(recommendationV2Response.sample.recommendations[this.selectedHit].hit_wt_pct);
    });

    this.simulatorDataService.recommendationV2DynamicResponse$.subscribe(_ => {
      this.selectedHit = this.simulatorDataService.selectedHitRecommendation;
      this.updatePigments(this.simulatorDataService.recommendationV2ApiData.sample.recommendations[this.selectedHit].hit_wt_pct);
    });

    this.simulatorDataService.errorResponse$.subscribe((source) => {
      if (source === ErrorComponentSource.pigment_info_card) {
        this.isLoadingError = true;
      }
    });
  }

  isChanged(Item:any){
    return Item.value != this.simulatorDataService.initialPigmentVals[this.selectedHit][Item.pigment];
  }

  getTickMax(max_hit_percentage: number): number {
    const positiveMaxHitPercentage = Math.abs(max_hit_percentage);
    return positiveMaxHitPercentage !== 0 ? parseFloat(positiveMaxHitPercentage.toFixed(2)) : 1.00;
  }

  private loadPigments(pigmentsList: any) {
    this.pigments = [];
    this.pigmentsfixed = [];
    pigmentsList.forEach(pigmentItem => {
      let tickMax = this.getTickMax(pigmentItem.max_hit_percentage);
      this.pigments.push({
        pigment: pigmentItem.pigment_id, floor: -tickMax, ceil: tickMax, "step": this.step,
        ticksArray: this.createTicksRange(tickMax), showTicks: true, showSelectionBarFromValue: 0,
        value: 0, highValue: 0, recommendations: true,
        getLegend: (value: number): string => {
          if (parseFloat(value.toFixed(2)) === -tickMax)
            return "min";
          else if (parseFloat(value.toFixed(2)) == tickMax)
            return "max";
        },
      })
      this.pigmentsfixed.push({
        pigment: pigmentItem.pigment_id, floor: -tickMax, ceil: tickMax,
        "step": this.step, showTicks: false, readOnly: true, value: 0,
        recommendations: true,
        combineLabels: (): string => {
          return 'pred. adj.';
        },
        translate: (label: LabelType): string => {
          return (label === LabelType.Low) ? '0' : 'pred. adj.';
        }
      })
    });
    this.simulatorDataService.pigments = this.pigments;
  }

  getLowValue(predAdjValue: number): number {
    return predAdjValue < 0 ? predAdjValue : 0;
  }

  getHighValue(predAdjValue: number): number {
    return predAdjValue >= 0 ? predAdjValue : 0;
  }

  getAdjustmentCssClass(predAdjValue: number): string {
    return predAdjValue < 0 ? 'negative-adjustment' : 'positive-adjustment';
  }

  private updatePigments(recommendationPigment: any) {
    this.pigments.forEach(pigmentItem => {
      pigmentItem.value = parseFloat(recommendationPigment[pigmentItem.pigment]).toFixed(this.pigmentValueDecimalSize);
    })

    if(this.simulatorDataService.initialRecommendationV2ApiPigmentHitRecommendations.has(this.selectedHit)) {
      this.pigmentsfixed.forEach(pigmentItem => {
        pigmentItem.value = this.simulatorDataService.initialRecommendationV2ApiPigmentHitRecommendations.get(this.selectedHit)[pigmentItem.pigment];
      })
    }

    if (this.simulatorDataService.initialLoad) {

      if (this.constOrderedPigments.length === 0) {
        this.pigmentsfixed.sort((a, b) => (a.value > b.value) ? -1 : 1);
        this.pigmentsfixed.forEach(pigmentItem => {
          this.constOrderedPigments.push(pigmentItem.pigment)
        })
      } else {
        this.mapOrder(this.pigmentsfixed, this.constOrderedPigments, 'pigment');
      }

      this.mapOrder(this.pigments, this.constOrderedPigments, 'pigment');
    }

    this.simulatorDataService.initialLoad = false;
  }

  private ensureInRange(value: any, min: Number, max: Number): Number {
    value = Number(value);

    if (value > max) {
      value = max;
    }
    if (value < min) {
      value = min;
    }

    return Number(value.toFixed(this.pigmentValueDecimalSize));
  }

  mapOrder(array, order, key) {
    array.sort((a, b) => {
      const A = a[key];
      const B = b[key];
      return order.indexOf(A) > order.indexOf(B) ? 1 : -1;
    });
    return array;
  }

  resetall() {
    for (let i in this.pigmentsfixed) {
      this.pigments[i].value = this.pigmentsfixed[i].value
    }
  }

  createTicksRange(maxvalue: any): any[] {
    const ticks: any[] = [];
    const step = maxvalue / 10;

    for (let i: number = -maxvalue; i <= maxvalue; i += step) {
      ticks.push(Number(i.toFixed(2)));
    }

    const zeroIndex = Math.floor(ticks.length / 2);
    ticks.splice(zeroIndex, 0, 0);

    return ticks;
  }

  onChangeEvent(Item: any, changedval: any, index: any) {
    changedval = this.ensureInRange(changedval, Item.floor, Item.ceil);

    this.simulatorDataService.setConcentration(Item.pigment, changedval);
    this.valueChanged$.next(changedval);

    this.pigmentPositionForGA = index + 1;
    this.pigmentsCountForGA = Object.keys(this.simulatorDataService.recommendationV2ApiData.sample.recommendations[this.selectedHit].hit_wt_pct).length
  }

  resetSlider(Item: any) {
    Item.value = this.simulatorDataService.initialPigmentVals[this.selectedHit][Item.pigment];

    this.simulatorDataService.setConcentration(Item.pigment, Item.value);
    this.valueChanged$.next(Item);
  }
}
