import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GraphSettings } from '../models/graphSettings';

@Injectable({
  providedIn: 'root'
})
export class GraphSettingsService {
  public graphSettingsChanged$ = new Subject<GraphSettings>();
}
