<pmr-modal
  [id]="id"
  [modalState]="isModalDisplayed"
  (opened)="modalOpened()"
  (closed)="modalClosed()"
  ariaLabelledBy="app-faq-modal"
>
  <pmr-modal-header styleModifier="header-style">
    <pmr-heading tagName="h2"> {{ 'FAQ.TITLE' | translate }} </pmr-heading>
  </pmr-modal-header>
  <pmr-modal-body>
    <div class="modal-main">
      <h3 class="select-topic">{{ 'FAQ.SELECT_TOPIC' | translate }}</h3>
      <div class="dropdown-menu">
        <p-dropdown
          class="dropdown"
          [options]="availableTopics"
          [(ngModel)]="selectedTopic"
          (ngModelChange)="onSelectedTopic($event)"
          [showClear]="false"
          [placeholder]="'FAQ.TOPIC_PLACEHOLDER' | translate"
        ></p-dropdown>
      </div>
      <div class="dropdown-options" *ngIf="questions">
        <p-accordion>
          <div *ngFor="let question of questions">
            <p-accordionTab [header]="question.QUESTION">
              <ng-template pTemplate="content">
                <p [innerHTML]="question.ANSWER"></p>
                <div class="column" *ngIf="isVisualisationAvailable(question)">
                  <p class="visualisation-header">
                    {{ question.VISUALISATION.TITLE }}
                  </p>
                  <img
                    [src]="getImageSrc(question.VISUALISATION.URL)"
                    alt="{{ question.VISUALISATION.TITLE }}"
                    class="graph-image graph-image-extra-padding-bottom"
                  />
                  <p class="visualisation-description">
                    {{ question.VISUALISATION.DESCRIPTION }}
                  </p>
                </div>
              </ng-template>
            </p-accordionTab>
          </div>
        </p-accordion>
      </div>
    </div>
    <div class="alemba-link">
      <p>
        {{ "FAQ.ALEMBA" | translate }}
        <span>
            <pmr-button
            variant="link"
            type="button"
            isExternal="true"
            [href]="alembaLink"
            target="_blank"
            [text]="'FAQ.ALEMBA_LINK' | translate "
            iconName="open-in-new"
            iconPosition="after"
            icons="assets/pmr-icons.svg"
          ></pmr-button>
        </span>
      </p>
    </div>
  </pmr-modal-body>
  <pmr-modal-footer styleModifier="modal-footer">
    <pmr-button
      variant="primary"
      [text]=" 'INFO.CLOSE' | translate"
      (click)="closeModal()"
      [fullWidth]="true"
    ></pmr-button>
  </pmr-modal-footer>
</pmr-modal>
