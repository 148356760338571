import { ErrorHandler, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApplicationInsightsLoggingService } from '../ApplicationInsights/application-insights-logging.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService extends ErrorHandler {
  public tryReloadData$ = new Subject<any>();
  constructor(private applicationInsightsLoggingService: ApplicationInsightsLoggingService) {
    super();
  }

  handleError(error: Error) {
    super.handleError(error)
    this.applicationInsightsLoggingService.logException(error);
  }
  tryReload() {
    this.tryReloadData$.next(true);
  }
}
