import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TooltipResizeRequestService } from '../shared/tooltip-resize-request-service/tooltip-resize-request.service';
import { ClickableTooltipComponent } from './clickable-tooltip.component';

@Directive({
  selector: '[appClickableTooltip]',
})
export class ClickableTooltipDirective implements OnInit {
  @Input() tooltipText = '';
  @Input() tooltipTitle = '';
  @Input() isTooltipButtonVisible = false;
  @Input() tooltipButtonText = '';
  @Input() tooltipStartPostionChildElement = '';
  @Input()
  get isVisible(): boolean {
    return this._isVisible;
  }
  set isVisible(value: boolean) {
    setTimeout(() => {
      this._isVisible = value;
      this.createAndSetPropertiesIfNeeded();
      this.componentRef.instance.isVisible = value;
    }, 50);
  }
  private _isVisible: boolean;
  @Output() tooltipButtonClick: EventEmitter<any> = new EventEmitter();
  private componentRef: ComponentRef<any> | null = null;

  constructor(
    private elementRef: ElementRef,
    private appRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private tooltipResizeRequestService: TooltipResizeRequestService
  ) { }

  ngOnInit() {
    this.tooltipResizeRequestService.resizeChangeRequest$.subscribe(() => {
      setTimeout(() => {
        this.setElementPosition();
      }, 50);
    });
  }

  private createAndSetPropertiesIfNeeded() {
    if (this.componentRef === null) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          ClickableTooltipComponent
        );
      this.componentRef = componentFactory.create(this.injector);

      this.appRef.attachView(this.componentRef.hostView);
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);
    }
    this.setTooltipComponentProperties();
  }

  @HostListener('click')
  onMouseClick(): void {
    this.createAndSetPropertiesIfNeeded();
    this.componentRef.instance.isVisible = true;
  }

  @HostListener('window:resize')
  onResize() {
    this.setElementPosition();
  }

  private setTooltipComponentProperties() {
    if (this.componentRef !== null) {
      this.componentRef.instance.tooltipText = this.tooltipText;
      this.componentRef.instance.tooltipTitle = this.tooltipTitle;
      this.componentRef.instance.isTooltipButtonVisible =
        this.isTooltipButtonVisible;
      this.componentRef.instance.tooltipButtonText = this.tooltipButtonText;
      this.componentRef.instance.tooltipButtonClick = this.tooltipButtonClick;

      this.setElementPosition();
    }
  }

  private setElementPosition() {
    let { left, right, top } =
      this.elementRef.nativeElement.getBoundingClientRect();

    if (
      this.tooltipStartPostionChildElement !== null &&
      this.tooltipStartPostionChildElement !== undefined &&
      this.tooltipStartPostionChildElement !== ''
    ) {
      let childStartPosition =
        this.elementRef.nativeElement.getElementsByTagName(
          this.tooltipStartPostionChildElement
        );

      if (childStartPosition !== null) {
        ({left, right, top}  =
         childStartPosition[0].getBoundingClientRect());
      }
    }

    this.componentRef.instance.left =
      window.scrollX + (right - left) / 2 + left;
    this.componentRef.instance.top = window.scrollY + top - 10;
  }
}
