import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SimulatorDataService } from 'src/simulator-data-service/simulator-data.service';
import { GraphSettingsService } from '../shared/graph-settings-service/graph-settings.service';
import { ErrorComponentSource } from '../shared/models/errorComponentSource';
import {
  GraphSettingsMode,
  GraphSettings,
  GraphToleranceGuidanceMode,
} from '../shared/models/graphSettings';
import { responseRecommendationV2 } from '../shared/models/responseRecommendationV2';
import { TooltipResizeRequestService } from '../shared/tooltip-resize-request-service/tooltip-resize-request.service';
import { GoogleAnalyticsService } from '../shared/google-analytics-service/google-analytics.service';

@Component({
  selector: 'app-graph-settings',
  templateUrl: './graph-settings.component.html',
  styleUrls: ['./graph-settings.component.scss'],
})
export class GraphSettingsComponent implements OnInit {
  items: any[] = [
    { value: GraphSettingsMode.averages_only, label: '' },
    {
      value: GraphSettingsMode.averages_variation,
      label: '',
    },
    {
      value: GraphSettingsMode.hide_current_position,
      label: '',
    },
  ];
  graphSettingsMode = GraphSettingsMode;
  graphSettings: GraphSettings = GraphSettings.createGraphSettings(
    GraphSettingsMode.averages_variation
  );
  graphToleranceGuidanceMode = GraphToleranceGuidanceMode;
  isExpanded: boolean = false;
  isErrorDisplaying: boolean = true;
  iconName: string = 'chevron-down';

  constructor(
    public translate: TranslateService,
    private graphSettingsService: GraphSettingsService,
    private simulatorDataService: SimulatorDataService,
    private tooltipResizeRequestService: TooltipResizeRequestService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.simulatorDataService.recommendationV2Response$.subscribe(
      (response: responseRecommendationV2) => {
        this.isErrorDisplaying = false;
        this.graphSettings.graphToleranceGuidanceMode =
          this.getToleranceGuidanceMode(
            response.sample.is_box_tolerance_method
          );
      }
    );

    this.simulatorDataService.recommendationV2DynamicResponse$.subscribe(() => {
      this.isErrorDisplaying = false;
    });

    this.simulatorDataService.errorResponse$.subscribe((source) => {
      if (source === ErrorComponentSource.tolerance_table_and_graphs) {
        this.isErrorDisplaying = true;
      }
    });

    this.getTranslations();
  }

  getTranslations() {
    this.translate
      .get(["INFO.GRAPH_SETTINGS_AVERAGES_ONLY", "INFO.GRAPH_SETTINGS_AVERAGES_VARIATION",
        "INFO.GRAPH_SETTINGS_AVERAGES_HIDE_CURRENT_POSITION"])
      .subscribe((translation: string[]) => {
        this.items.find(x => x.value === GraphSettingsMode.averages_only).label = translation["INFO.GRAPH_SETTINGS_AVERAGES_ONLY"];
        this.items.find(x => x.value === GraphSettingsMode.averages_variation).label = translation["INFO.GRAPH_SETTINGS_AVERAGES_VARIATION"];
        this.items.find(x => x.value === GraphSettingsMode.hide_current_position).label = translation["INFO.GRAPH_SETTINGS_AVERAGES_HIDE_CURRENT_POSITION"];
      });
  }

  expandCollapseSettings() {
    this.isExpanded = !this.isExpanded;
    this.iconName = this.isExpanded ? 'chevron-up' : 'chevron-down';
    this.tooltipResizeRequestService.resizeChangeRequest$.next(true);
  }

  private getToleranceGuidanceMode(
    isBoxToleranceMethod: boolean
  ): GraphToleranceGuidanceMode {
    return isBoxToleranceMethod
      ? GraphToleranceGuidanceMode.tolerance
      : GraphToleranceGuidanceMode.guidance;
  }

  onGraphActionChange(eventTargetValue: GraphSettingsMode) {
    this.graphSettings.graphMode = eventTargetValue;
    this.graphSettingsService.graphSettingsChanged$.next(this.graphSettings);

    this.googleAnalyticsService.pushData(
      'graph_set_' + GraphSettingsMode[eventTargetValue],
      'Graph Settings ' + GraphSettingsMode[eventTargetValue]
    );
  }

  onPredictedColorShownChange(isChecked: boolean) {
    this.graphSettings.isPredictedColorShown = isChecked;
    this.graphSettingsService.graphSettingsChanged$.next(this.graphSettings);

    this.googleAnalyticsService.pushData(
      'pred_color_chkbx_' + isChecked,
      'Predicted Color Checkbox ' + isChecked
    );
  }

  onToleranceLimitShownChange(isChecked: boolean) {
    this.graphSettings.isToleranceLimitsShown = isChecked;
    this.graphSettingsService.graphSettingsChanged$.next(this.graphSettings);

    this.googleAnalyticsService.pushData(
      'tol_lim_chkbx_' + isChecked,
      'Tolerance Limit Checkbox ' + isChecked
    );
  }

  onGuidanceLimitShownChange(isChecked: boolean) {
    this.graphSettings.isGuidanceLimitsShown = isChecked;
    this.graphSettingsService.graphSettingsChanged$.next(this.graphSettings);

    this.googleAnalyticsService.pushData(
      'gui_lim_chkbx_' + isChecked,
      'Guidance Limit Checkbox ' + isChecked
    );
  }
}
