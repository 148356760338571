import { Component, OnInit } from '@angular/core';
import { LoadingService } from '../shared/loading-service/loading.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.scss']
})
export class LoadingPageComponent implements OnInit {
  loading$ = this.loader.loading$;

  constructor(public loader: LoadingService,
    public spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.loading$ ? this.spinner.show() : this.spinner.hide();
  }
}
