import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastMessagingService } from '@app-dev/notification-framework';
import { catchError, } from 'rxjs/operators';
import { ErrorTranslationService } from '../error-translation-service/error-translation.service';

@Injectable()
export class MlErrorInterceptor implements HttpInterceptor {

  constructor(private toastNotificationService: ToastMessagingService, private errorTranslationService: ErrorTranslationService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if ((request.url.includes("/ppg/aiml/colorai/request") && request.method === "GET") || request.url.includes("/ppg/aiml/colorai/recommendation/dynamic")) {
      return next.handle(request).pipe(
        catchError((response: HttpErrorResponse) => {
          if (!(response.error instanceof ErrorEvent)) {
            this.toastNotificationService.showMessage({
              title: this.errorTranslationService.getTranslatedErrorTitle(response.error.internalErrorCode),
              text: this.errorTranslationService.translateError(response.error.message, response.error.internalErrorCode),
              type: 'error'
            });
          }
          throw response;
        })
      );
    }
    return next.handle(request);
  }
}
