import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SimulatorDataService } from 'src/simulator-data-service/simulator-data.service';
import { angle } from '../shared/models/recommendationRequestDto';
import { recommendation, sampleInfo } from '../shared/models/responseRecommendationV2';
import { responseRecommendationV2Dynamic } from '../shared/models/responseRecommendationV2Dynamic';
import { ErrorComponentSource } from '../shared/models/errorComponentSource';
import { TooltipResizeRequestService } from '../shared/tooltip-resize-request-service/tooltip-resize-request.service';
import { GoogleAnalyticsService } from '../shared/google-analytics-service/google-analytics.service';

@Component({
  selector: 'app-tolerance-table',
  templateUrl: './tolerance-table.component.html',
  styleUrls: ['./tolerance-table.component.scss'],
})
export class ToleranceTableComponent implements OnInit {
  toggleIcon = 'toggle_on';
  showTable = true;
  isToleranceAvailable: boolean;
  isError = false;
  iconName: string;
  isSuccess: false;
  lastUpdatedinColorPortal: string;
  toleranceType: string;
  toleranceMethod: string;
  isToggleShown = true;
  mDeltaE: string;
  mDeltaEStatus: string;
  angles: Map<string, angle>;
  statusIndicator25OverAll: string;
  isLoadingError = false;

  constructor(
    private simulatorDataService: SimulatorDataService,
    private tooltipResizeRequestService: TooltipResizeRequestService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.simulatorDataService.recommendationV2Response$.subscribe({
      next: (data) => {
        this.isLoadingError = false;
        this.loadInitialData(data.sample);
      },
      error: (_) => {
        this.isLoadingError = true;
        this.isError = true;
      },
    });
    this.simulatorDataService.recommendationV2DynamicResponse$.subscribe((data) => {
      this.isLoadingError = false;
      this.loadDynamicData(
        this.simulatorDataService.recommendationV2ApiData.sample,
        data
      );
    });

    this.simulatorDataService.errorResponse$.subscribe((source) => {
      if (source === ErrorComponentSource.tolerance_table_and_graphs) {
        this.isLoadingError = true;
      }
    });
  }

  originalOrder = (a: KeyValue<any, any>, b: KeyValue<any, any>): any => {
    return 0;
  };

  toleranceInfoAvailable() {
    if (this.toleranceMethod == 'N/A') {
      this.isToleranceAvailable = false;
      this.iconName = 'alert-circle';
    } else {
      this.isToleranceAvailable = true;
    }
  }

  private loadInitialData(initialSample: sampleInfo) {
    let initialRecommendation = initialSample.recommendations[this.simulatorDataService.selectedHitRecommendation] as recommendation;

    this.lastUpdatedinColorPortal = initialSample.tolerance_update_date;
    this.toleranceType = initialSample.tolerance_type;
    this.toleranceMethod = initialSample.tolerance_method;
    this.mDeltaE = initialRecommendation.mde;
    this.mDeltaEStatus = initialRecommendation.mde_flag;
    this.angles = initialRecommendation.angles as any;
    this.toleranceInfoAvailable();
  }

  private loadDynamicData(
    initialSample: sampleInfo,
    dynamicData: responseRecommendationV2Dynamic
  ) {
    this.lastUpdatedinColorPortal = initialSample.tolerance_update_date;
    this.toleranceType = initialSample.tolerance_type;
    this.toleranceMethod = initialSample.tolerance_method;
    this.mDeltaE = dynamicData.mde;
    this.mDeltaEStatus = dynamicData.mde_flag;
    this.angles = dynamicData.angles as any;
    this.toleranceInfoAvailable();
  }

  toggleTable() {
    if (this.showTable) {
      this.showTable = false;
      this.toggleIcon = 'toggle_off';
    } else if (!this.showTable) {
      this.showTable = true;
      this.toggleIcon = 'toggle_on';
    }

    this.tooltipResizeRequestService.resizeChangeRequest$.next(true);
    this.googleAnalyticsService.pushData(
      'tol_toggle_' + this.showTable,
      'Tolerance toggle ' + this.showTable
    );
  }
}
