<div class="legendsHeader">
  <div class="legendsContainer">
    <span> {{ "INFO.KEY" | translate }} : </span>
    <span class="predictLegends">
      <ul>
        <li>{{ "INFO.AVG_CURRENT_COLOR" | translate }}</li>
        <li class="graphKeyLine">
          <svg width="40" height="2" viewBox="0 0 40 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="0.828613" x2="40" y2="0.828613" stroke="#92979E" stroke-dasharray="4 4" />
          </svg>
        </li>
        <li>{{ "INFO.PREDICTED_COLOR" | translate }}</li>
        <li class="graphKeyLine">
          <svg width="40" height="2" viewBox="0 0 40 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="0.828613" x2="40" y2="0.828613" stroke="#1C883A" stroke-width="2" />
          </svg>
        </li>
      </ul>
    </span>
  </div>
  <div>
    <span class="iconstyle">
      <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="#FDA101" class="bi bi-circle-fill"
        viewBox="0 0 16 16">
        <circle cx="8" cy="8" r="8" />
      </svg>
      15
    </span>
    <span class="iconstyle">
      <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="#0078A9" class="bi bi-square-fill"
        viewBox="0 0 16 16">
        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2z" />
      </svg>
      25
    </span>
    <span class="iconstyle">
      <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="#252F3D" class="bi bi-suit-diamond-fill"
        viewBox="0 0 16 16">
        <path
          d="M2.45 7.4 7.2 1.067a1 1 0 0 1 1.6 0L13.55 7.4a1 1 0 0 1 0 1.2L8.8 14.933a1 1 0 0 1-1.6 0L2.45 8.6a1 1 0 0 1 0-1.2z" />
      </svg>
      45
    </span>
    <span class="iconstyle">
      <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="#CA1041" class="bi bi-star-fill"
        viewBox="0 0 16 16">
        <path
          d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
      </svg>
      75
    </span>
    <span class="iconstyle">
      <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="#80499A" class="bi bi-triangle-fill"
        viewBox="0 0 16 16">
        <path fill-rule="evenodd"
          d="M7.022 1.566a1.13 1.13 0 0 1 1.96 0l6.857 11.667c.457.778-.092 1.767-.98 1.767H1.144c-.889 0-1.437-.99-.98-1.767L7.022 1.566z" />
      </svg>
      110
    </span>
  </div>
</div>
<app-failed-loading [ngStyle]="{ display: isLoadingError === true ? 'inline' : 'none' }"
  errorMessage="{{ 'INFO.FAILED_TO_LOAD_GRAPH_COMPONENT' | translate }}" class="failed-content"></app-failed-loading>
<app-guidance-pill [attr.data-cy]="'guidance-pill'" class="app-guidance-pill"></app-guidance-pill>
<table [ngStyle]="{ display: isLoadingError === true ? 'none' : 'block' }">
  <tr *ngFor="let chart of charts">
    <td class="delta-style">{{ chart.deltaName }}</td>
    <td class="graphBox">
      <app-graph [graphData]="chart.changed" [graphRemoved]="chart.removed" [deltaName]="chart.deltaName"></app-graph>
    </td>
  </tr>
</table>