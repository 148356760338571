import { Component, Input, OnInit } from '@angular/core';
import { ErrorHandlingService } from '../shared/error-handling-service/error-handling.service';

@Component({
  selector: 'app-failed-loading',
  templateUrl: './failed-loading.component.html',
  styleUrls: ['./failed-loading.component.scss']
})
export class FailedLoadingComponent {

  @Input()
  errorMessage: string;

  constructor(private errorHandlingService: ErrorHandlingService) { }

  tryLoadAgain(): void {
    this.errorHandlingService.tryReload();
  }
}
