import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@primer/angular-components';
import { Observable } from 'rxjs';
import { ConfigsLoaderService } from '../shared/config-loader-service/config-loader-service';

@Component({
  selector: 'app-faq-modal',
  templateUrl: './faq-modal.component.html',
  styleUrl: './faq-modal.component.scss',
})
export class FaqModalComponent {
  public id: string = 'faq-modal';
  private ALEMBA_URL: string = this.config.alembaTicketingUrl;
  alembaLink: string = this.ALEMBA_URL;
  isModalDisplayed: boolean;
  questions: any[];
  availableTopics: any[];
  selectedTopic: string | undefined;
  topicPlaceholderText: string;
  buttonText: string;
  alembaLinkText: string;
  parsedJson: any;

  constructor(
    private modalService: ModalService,
    protected translate: TranslateService,
    private http: HttpClient,
    private config: ConfigsLoaderService
  ) {
    this.getTranslatedTopics().subscribe((data) => {
      this.parseTopics(data);
    });
  }

  public getTranslatedTopics(): Observable<any> {
    const lang = this.translate.currentLang;
    const url = `/assets/i18n/sim-faq-content/${lang}.json`;
    return this.http.get(url);
  }

  parseTopics(jsonFile) {
    this.parsedJson = JSON.parse(JSON.stringify(jsonFile));
    this.availableTopics = this.parsedJson.TOPICS.map((topic) => ({
      value: topic.NAME,
      label: topic.NAME,
    }));
  }

  onSelectedTopic($event) {
    if (this.parsedJson.TOPICS && this.parsedJson.TOPICS.length > 0) {
      this.questions = this.parsedJson.TOPICS.filter(
        (topic) => topic.NAME == this.selectedTopic
      )[0].FAQ;
    } else {
        console.log('parsedJson.Features is undefined or empty');
      }
  }

  isVisualisationAvailable(question: any): boolean {
    return (
      question.VISUALISATION !== undefined &&
      question.VISUALISATION !== null &&
      String(question.VISUALISATION).toLowerCase() !== 'none'
    );
  }

  getImageSrc(url: string) {
    return '/assets/faq-docs/' + url;
  }

  display() {
    this.isModalDisplayed = true;
  }

  modalOpened(): void {
    this.selectedTopic = undefined;
    this.questions = [];
    this.isModalDisplayed = true;
  }

  modalClosed(): void {
    this.isModalDisplayed = false;
  }

  closeModal(): void {
    this.isModalDisplayed = false;
    this.modalService.close(this.id);
  }
}
