<ng-container *ngIf="isDevelopmentOnGoing; else loadingScreen">
  <app-on-going-development-modal></app-on-going-development-modal>
</ng-container>
<ng-template #loadingScreen>
  <app-loading-page></app-loading-page>
</ng-template>

<div [ngClass]="{ 'blur-background': isDevelopmentOnGoing }">
  <div class="navBar">
    <app-nav-bar></app-nav-bar>
  </div>
  <div class="container">
    <div class="main-sim">
      <div class="sim-top">
        <div class="headerSection">
          <app-header></app-header>
        </div>

        <lib-inline-notification-list></lib-inline-notification-list>

      <div class="mainLayout">
        <div class="innerContainer">
          <div class="twoColumLayout">
            <div class="leftColumn">
              <app-hit-recommendations></app-hit-recommendations>
              <app-adjustment-card-header></app-adjustment-card-header>
              <div class="pigmentContainer">
                <app-pigment-info-card></app-pigment-info-card>
              </div>
            </div>
            <div class="rightColumn">
              <h3>{{ "INFO.GRAPH_SUBHEADER" | translate }}</h3>
              <p>
                {{ "INFO.GRAPH_DESC" | translate }}
              </p>
              <div class="tolerance-table-container">
                <app-tolerance-table></app-tolerance-table>
              </div>
              <div class="graphContainer">
                <app-graph-settings class="app-graph-settings"></app-graph-settings>
                <app-graph-list class="app-graph-list"></app-graph-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
    <!--Modals-->
    <app-announcement-modal modalTitle="{{ 'INFO.INTRO_TOLERANCE_TABLE_HEADER' | translate }}"
      modalBodyText="{{ 'INFO.INTRO_TOLTABLE_DESC' | translate }}">
    </app-announcement-modal>
    <app-guidance-announcement-modal></app-guidance-announcement-modal>
    <app-send-adjustment-modal [submitPigments]="pigments"></app-send-adjustment-modal>
    <app-faq-modal></app-faq-modal>
    <app-release-note-modal></app-release-note-modal>
  </div>
</div>
<lib-toast-notification-list *ngIf="!isDevelopmentOnGoing"></lib-toast-notification-list>
