<div *ngIf="isLoadingError; then isLoadingErrorBlock; else contentBlock"></div>
<ng-template #isLoadingErrorBlock>
  <app-failed-loading class="failed-content"
    errorMessage="{{ 'INFO.FAILED_TO_LOAD_PIGMENT_COMPONENT' | translate }}"></app-failed-loading>
</ng-template>
<ng-template #contentBlock>
  <div class="pigment-card-container">
    <table aria-label="Pigment Card" *ngFor="let item of pigments; index as indexOfelement"
      class="no-border pigment-card">
      <tr class="trh3 pc-table-row">
        <td colspan="3" class="pigment-text">{{ item.pigment }}</td>
        <td style="float: right">
          <svg *ngIf="!isAdjustmentSaved" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
            class="bi bi-lock" viewBox="0 0 16 16">
            <path
              d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
          </svg>
          <pmr-button *ngIf="item.recommendations" [disabled]="!isChanged(item)"
            id="a_{{ indexOfelement }}"
            [attr.data-cy]="'pigment-' + (indexOfelement + 1) + '-reset-button'"
            variant="bare"
            icons="assets/icons/arrow-counterclockwise.svg"
            iconName="arrow-counterclockwise"
            iconPosition="before"
            styleModifier="reset-slider-button"
            (click)="resetSlider(item)">
          </pmr-button>
        </td>
      </tr>

      <div class="recommendation-header">
        <div class="rec-header">
          {{ "INFO.ADJUSTMENT_PERCENT_ABBR" | translate }}
        </div>
      </div>

      <tr *ngIf="item.recommendations" class="graph padding-top pc-table-row bottom-row">
        <td colspan="2" class="slider-holder">
          <div class="custom-slider">
            <ngx-slider [attr.data-cy]="'pigment-' + (indexOfelement + 1) + '-slider'" [(value)]="item.value"
              [options]="item" id="{{ indexOfelement }}"
              (userChange)="onChangeEvent(item, $event.value, indexOfelement)">
            </ngx-slider>
          </div>
          <div class="hiddenslider">
            <ngx-slider [value]="getLowValue(pigmentsfixed[indexOfelement].value)" [highValue]="getHighValue(pigmentsfixed[indexOfelement].value)" [options]="pigmentsfixed[indexOfelement]"
              id="{{ indexOfelement }}_fixed" [ngClass]="getAdjustmentCssClass(pigmentsfixed[indexOfelement].value)">
            </ngx-slider>
          </div>
        </td>
        <td>
          <input [attr.data-cy]="'pigment-' + (indexOfelement + 1) + '-input'" type="text"
            id="{{ indexOfelement }}_text" [ngClass]="{
              'suggested-hit-color':
                item.value === pigmentsfixed[indexOfelement].value,
              'selected-hit-color': !(
                item.value === pigmentsfixed[indexOfelement].value
              )
            }" size="1" [(ngModel)]="item.value" [ngModelOptions]="{ updateOn: 'blur' }" type="number"
            [min]="item.floor" [max]="item.ceil" [step]="item.step"
            (blur)="onChangeEvent(item, $event.target.value, indexOfelement)" />
        </td>
      </tr>
      <tr *ngIf="!item.recommendations" class="graph">
        <td colspan="4">{{ "USER_INFO.ELSEMSG" | translate }}</td>
      </tr>
    </table>
  </div>
</ng-template>
