import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SimulatorDataService } from 'src/simulator-data-service/simulator-data.service';
import { ErrorComponentSource } from '../shared/models/errorComponentSource';
import { GoogleAnalyticsService } from '../shared/google-analytics-service/google-analytics.service';

@Component({
    selector: 'app-adjustment-card-header',
    templateUrl: './adjustment-card-header.component.html',
    styleUrls: ['./adjustment-card-header.component.scss'],
})
export class AdjustmentCardHeaderComponent implements OnInit {
    requestGuid: string;
    errorMessage = '';
    isAdjustmentSaved = true;
    isLoadingError = false;

    constructor(
        private route: ActivatedRoute,
        public simulatorDataService: SimulatorDataService,
        private googleAnalyticsService: GoogleAnalyticsService
    ) { }

    ngOnInit(): void {
        this.simulatorDataService.errorResponse$.subscribe((source) => {
            if (source === ErrorComponentSource.pigment_info_card) {
                this.isLoadingError = true;
            }
        });

        this.simulatorDataService.formulaResponse$.subscribe(() => {
            this.isLoadingError = false;
        });
    }

    resetAll(event): void {
        this.route.queryParams.forEach((param) => {
            if (param.requestGuid) {
                this.requestGuid = param.requestGuid;
            }
        });

        this.simulatorDataService.resetDynamicResponses();

        this.simulatorDataService.resetAllResponse$.next(null);
        this.simulatorDataService.initialLoad = true;

        this.googleAnalyticsService.pushData(
            'reset_all',
            'Reset All'
        );
    }
    submitEdits() {
        this.simulatorDataService.getRecommendationDynamicData().subscribe(
            _=>{
              this.googleAnalyticsService.pushData(
                'submit_edits',
                'Submit edits'
              )}
          );
    }
}
