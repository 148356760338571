import { Component, ElementRef, AfterViewInit, ViewChild } from '@angular/core';
import { ModalService } from '@primer/angular-components';
import { SimulatorDataService } from 'src/simulator-data-service/simulator-data.service';
import { responseRecommendationV2 } from '../shared/models/responseRecommendationV2';
import { TooltipResizeRequestService } from '../shared/tooltip-resize-request-service/tooltip-resize-request.service';

@Component({
    selector: 'app-guidance-pill',
    templateUrl: './guidance-pill.component.html',
    styleUrls: ['./guidance-pill.component.scss'],
})
export class GuidancePillComponent implements AfterViewInit {
    @ViewChild('tooltipPill') tooltipPill: ElementRef;
    modalId: string = 'guidance-announcement-modal';
    isGuidanceVisible: boolean = false;
    expirationDate = new Date(2023, 2, 21);
    isToolTipVisible: boolean = false;

    constructor(
        private modalService: ModalService,
        private simulatorDataService: SimulatorDataService,
        private tooltipResizeRequestService: TooltipResizeRequestService
    ) {
        this.simulatorDataService.recommendationV2Response$.subscribe(
            (response: responseRecommendationV2) => {
                this.isGuidanceVisible = !response.sample.is_box_tolerance_method;
            }
        );
    }

    ngAfterViewInit(): void {
        this.checkIfToolTipShouldBeDisplayedOnInit();
    }

    checkIfToolTipShouldBeDisplayedOnInit() {
        if (this.expirationDate !== null) {
            let date = new Date();
            let today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            if (today.getTime() <= this.expirationDate.getTime()) {
                this.isToolTipVisible = true;
            }
        }
    }

    openModal() {
        this.modalService.open(this.modalId);
    }
}
