import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { MsalService } from "@azure/msal-angular";
import { ConfigsLoaderService } from '../config-loader-service/config-loader-service';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  appAuthScope: string;

  constructor(private authService: MsalService, private config: ConfigsLoaderService) {
    this.appAuthScope = this.config.appAuthScope;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token: string;
    this.authService
      .acquireTokenSilent({
        account: this.authService.instance.getAllAccounts()[0],
        scopes: [
          this.appAuthScope,
        ],
      })
      .subscribe((x) => (token = x.accessToken));

    request.clone({
      headers: request.headers
        .set("Content-Type", "application/json")
        .set("Authorization", "Bearer " + token),
    });
    return next.handle(request);
  }
}
