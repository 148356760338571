import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { Component, OnInit, Inject } from "@angular/core";
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from "@azure/msal-angular";
import {
  InteractionStatus,
  RedirectRequest,
  EventMessage,
  EventType,
} from "@azure/msal-browser";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { TranslateService } from '@ngx-translate/core';
import { ConfigsLoaderService } from "./shared/config-loader-service/config-loader-service";
import 'src/app/shared/extentions'

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [
    trigger("slideInOut", [
      state(
        "in",
        style({
          transform: "translate3d(0,0,0)",
        })
      ),
      state(
        "out",
        style({
          transform: "translate3d(100%, 0, 0)",
        })
      ),
      transition("in => out", animate("400ms ease-in-out")),
      transition("out => in", animate("400ms ease-in-out")),
    ]),
  ],
})
export class AppComponent implements OnInit {
  title = "coloraisimulator";
  isIframe = false;
  loginDisplay = false;
  emailId!: string;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public translate: TranslateService,
    private config: ConfigsLoaderService
  ) { }

  ngOnInit() {
    this.setLoginDisplay();
    this.authService
      .acquireTokenSilent({
        account: this.authService.instance.getAllAccounts()[0],
        scopes: [this.config.appAuthScope],
      })
      .subscribe((x) => {
      });

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.setTranslateConfig();

    this.getTitleString();
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount
      // (this.authService.instance?.getAllAccounts() || []).length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/",
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  getTitleString() {
    // asynchronous - gets translations then completes.
    this.translate.get(['TITLE', 'USER_INFO.EMAIL_ADDRESS'])
      .subscribe(translations => {
        this.title = translations['TITLE'];
        this.emailId = translations['USER_INFO.EMAIL_ADDRESS'];
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  setTranslateConfig() {
    const defaultLanguage = 'en';
    const supportedLanguages = ['en', 'es'];
    const browserLanguage = this.translate.getBrowserLang();

    this.translate.setDefaultLang(defaultLanguage);
    this.translate.addLangs(supportedLanguages);
    const language = supportedLanguages.find((x) => x === browserLanguage) ?? defaultLanguage;
    this.translate.use(language);
  }
}
