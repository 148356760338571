import { Component, OnInit } from '@angular/core';
import { SimulatorDataService } from 'src/simulator-data-service/simulator-data.service';
import { ActivatedRoute } from '@angular/router';
import { initialSimulatorResponse } from '../shared/models/responseRecommendationV2';
import { ErrorHandlingService } from '../shared/error-handling-service/error-handling.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  requestGuid: string;
  errorMessage = '';
  
  get initialSimulatorResponse(): initialSimulatorResponse{
    return this.simulatorDataService.initialSimulatorResponse;
  }
  
  constructor(
    private simulatorDataService: SimulatorDataService,
    private errorHandlingService: ErrorHandlingService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams.forEach((param) => {
      if (param.requestGuid) {
        this.requestGuid = param.requestGuid;
        this.loadData();
      }
    });

    this.simulatorDataService.resetAllResponse$.subscribe({
      next: (data) => {
        this.loadData();
      },
    });

    this.errorHandlingService.tryReloadData$.subscribe({
      next: () => {
        this.loadData();
      },
    });
  }

  private loadData() {
    this.simulatorDataService
      .getFormulaData(this.requestGuid)
      .subscribe({

        error: (err) => {
          this.errorMessage = err;
          console.log('error get formula data ', this.errorMessage);
        },
      });
  }
}
