import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import * as d3 from 'd3';

@Component({
  selector: 'app-scatter',
  template: ''
})
export class ScatterComponent implements OnInit {
  options = [
    { "name": "0", "class": "na" },
    { "name": "0.10", "class": "na" },
    { "name": "0.20", "class": "na" },
    { "name": "0.24", "class": "na" },
    { "name": "0.30", "class": "option" },
    { "name": "0.40", "class": "na" },
    { "name": "0.48", "class": "na" },
    { "name": "0.50", "class": "na" },
    { "name": "0.60", "class": "na" },
    { "name": "0.72", "class": "na" },
    { "name": "0.80", "class": "na" },
    { "name": "0.90", "class": "na" },
    { "name": "0.96", "class": "na" },
    { "name": "1.00", "class": "na" },
    { "name": "1.20", "class": "na" },
    { "name": "1.50", "class": "na" }
  ];
  pigments: any[] = [
    { "index": "0", "min": 0, "max": "1.50", "step": "0.30", "value": 1.50, "recommendations": true },
    { "index": "1", "min": 0, "max": "1.20", "step": "0.24", "value": 1.20, "recommendations": true },
    { "index": "2", "min": 0, "max": "1.00", "step": "0.20", "value": 1.00, "recommendations": true },
    { "index": "3", "min": 0, "max": "0.50", "step": "0.10", "value": 0.50, "recommendations": true },
    { "index": "4", "min": 0, "max": "1", "step": "any", "value": "0.75", "recommendations": false },
    { "index": "5", "min": 0, "max": "1", "step": "any", "value": "0.75", "recommendations": false },
  ];

  data: any[] = [
    { "delta": "-0.75", "angle": "15", "index": "0", "color": "blue", "shape": "circle" },
    { "delta": "0.5", "angle": "25", "index": "1", "color": "yellow", "shape": "triangle" },
    { "delta": "0", "angle": "45", "index": "2", "color": "green", "shape": "diamond" },
    { "delta": "0.5", "angle": "75", "index": "3", "color": "red", "shape": "star" },
    { "delta": "1", "angle": "115", "index": "4", "color": "purple", "shape": "star" }
  ];

  private group = [
    { "category": "current", "color": "#FF0000" },
    { "category": "prediction", "color": "#32CD32" },
  ];
  private ylabel = [
    { "name": "~L" }, { "name": "~A" }, { "name": "~B" }
  ];
  private svg: any;
  private margin = 30;
  private width = 900 - (this.margin * 3);
  private height = 200 - (this.margin * 2);
  rangevalue = [1.50, 1.20, 1.00, 0.50];
  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    let graph = 0;
    while (graph < 3) {
      this.createSvg(graph);
      this.drawPlot(graph);
      this.renderprediction(0, graph);
      graph++;
    }
  }

  private createSvg(i: any): void {
    this.svg = d3.select("figure#scatter")
      .append("svg")
      .attr("class", "graph pigment_" + i)
      .attr("id", "pigment_" + i)
      .attr("width", this.width + (this.margin * 2))
      .attr("height", this.height + (this.margin * 2))
      .append("g")
      .attr("transform", "translate(" + this.margin + "," + this.margin + ")");
  }

  private drawPlot(i: any): void {
    // Add X axis
    const x = d3.scaleLinear()
      .domain([0, 120])
      .range([0, this.width]);

    d3.selectAll("g.axis--x g.tick")
      .append("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", 0)
      .attr("y2", -(this.height))
      .attr("opacity", 0.4);

    // Add Y axis
    const y = d3.scaleLinear()
      .domain([-2, 2])
      .range([this.height, 0]);
    this.svg.append("g")
      .attr('class', 'axis cursive axis--y pigment_' + i)
      .call(d3.axisLeft(y).ticks(5));

    d3.selectAll("g.axis--y g.tick")
      .append("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr("x2", this.width)
      .attr("y2", 0);

    // Add dots
    const dots = this.svg.append('g');
    dots.selectAll("dot")
      .data(this.data)
      .enter()
      .append("circle")
      .attr("cx", (d: any) => x(d.angle))
      .attr("cy", (d: any) => y(d.delta))
      .attr("r", 0)
      .style("opacity", .5)
      .style("fill", (d: any) => d.color);

    const valueline = d3.line()
      .x(function (d: any) {
        return x(d.angle);
      })
      .y(function (d: any) {
        return y(d.delta);
      });

    this.svg.append("path")
      .data([this.data])
      .attr("class", "line")
      .attr("d", valueline)
      .attr("stroke", "black")
      .style("stroke-dasharray", ("3, 3"))
      .attr("stroke-width", 0.5)
      .attr("fill", "none");
    // Add labels
  }

  renderprediction(_yval: any, i: any) {
    const x = d3.scaleLinear()
      .domain([0, 120])
      .range([0, this.width]);
    const y = d3.scaleLinear()
      .domain([-2, 2])
      .range([this.height, 0]);

    this.svg = d3.select("svg#pigment_" + i + " g");
    this.svg.selectAll(".dot")
      .data(this.data)
      .attr("class", "dot")
      .attr("id", "predcircle_" + i)
      .attr("r", 5)
      .attr("cx", (d: any) => x(d.angle))
      .attr("cy", function (d: any) {
        return y(parseFloat(d.delta) + parseFloat("0.60"));
      })
      .style("fill", (d: any) => d.color);
    ;

    const valueline2 = d3.line()
      .x(function (d: any) {
        return x(d.angle);
      })
      .y(function (d: any) {
        return y(parseFloat(d.delta) + parseFloat("0.60"));
      });

    this.svg.append("path")
      .data([this.data])
      .attr("id", "predpath_" + i)
      .attr("class", "line")
      .attr("d", valueline2)
      .attr("stroke", "#32CD32")
      .attr("stroke-width", 2)
      .attr("fill", "none");

    const symbol = d3.symbol();
    let dots = this.svg.selectAll(".dot")
      .data(this.data)
      .enter()
      .append("path");
    dots.attr("d", symbol.type(function (d: any) {
      if (d.index == 0) {
        return d3.symbolCircle;
      } else if (d.index == 1) {
        return d3.symbolTriangle;
      } else if (d.index == 2) {
        return d3.symbolDiamond;
      } else if (d.index == 3) {
        return d3.symbolStar;
      } else if (d.index == 4) {
        return d3.symbolSquare;
      } else {
        return d3.symbolCircle;
      }

    }))
      .attr("transform", (d: any, i: any) => "translate(" + x(d.angle) + "," + y(parseFloat(d.delta) + parseFloat("0.60")) + ")")
      .attr("r", 5)
      .style("fill", (d: any) => d.color);
  }

  grouplabels() {

    //group labels
    let legend = this.svg.selectAll(".legend")
      .data(this.group)
      .enter().append("g")
      .attr("class", "legend")
      .attr("transform", (_d: any, i: any) => "translate(110," + i * 20 + ")")
      ;

    legend.append("rect")
      .attr("x", this.width - 18)
      .attr("width", 18)
      .attr("height", 18)
      .style("fill", (d: any) => d.color)

    legend.append("text")
      .attr("x", this.width - 24)
      .attr("y", 9)
      .attr("dy", ".35em")
      .style("text-anchor", "end")
      .text((d: any) => d.category)
      ;
  }

  onChangeEvent(e: any) {
    this.rangevalue[e.target.id] = e.target.value;
  }
}
