import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
function bootstrapFailed(val: any) {
  console.error('bootstrap-fail', val);
}

fetch('assets/configs.json')
  .then(response => {
    return response.json();
  })
  .then(config => {
    if (!config || !config['isConfig']){
      bootstrapFailed(config);
      return;
    }
        // Store the response somewhere that your ConfigService can read it.
        (window as any).APP_CONFIG = config;

        platformBrowserDynamic()
          .bootstrapModule(AppModule)
          .catch(bootstrapFailed);
  })
  .catch(bootstrapFailed)
