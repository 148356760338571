import { Injectable } from '@angular/core';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  pushData(eventAction: string, eventLabel: string) {
    window.dataLayer.push({
      'event': 'customEvent',
      'eventCategory': 'ColorAISimulator',
      'eventAction': eventAction,
      'eventLabel': 'Color AI Simulator tool - ' + eventLabel
    });
  }
}
