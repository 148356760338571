<div id="navbar" class="navbar-main">
  <pmr-primary-nav styleModifier="nav-list">
    <div class="box">
      <div class="aligment">
        <pmr-logo styleModifier="ppg-logo"></pmr-logo>
      </div>
    </div>
    <pmr-utility-nav styleModifier="right-nav">
      <p-button
        label="{{ 'FAQ.FAQ' | translate }}"
        aria-label="{{ 'FAQ.FAQ' | translate }}"
        icon="pi pi-question-circle"
        styleClass="faq-button"
        [outlined]="true"
        (click)="onOpenFAQModal()"
      >
      </p-button>
      <pmr-utility-nav-item
        [attr.data-cy]="'username-button'"
        styleModifier="username"
        [id]="username"
        text="{{ username }}"
        iconName="account-circle-outline"
        [dropdown]="true"
        iconPosition="before"
      >
        <pmr-button
          [attr.data-cy]="'logout-button'"
          variant="primary"
          text="{{ 'INFO.LOGOUT' | translate }}"
          (click)="onLogout()"
          [fullWidth]="true"
        ></pmr-button>
      </pmr-utility-nav-item>
    </pmr-utility-nav>
  </pmr-primary-nav>
</div>
