<pmr-modal [id]="id" [modalState]="isModalDisplayed" (opened)="modalOpened()" (closed)="modalClosed()"
  class="announcementModalId" ariaLabelledBy="app-announcement-modal" styleModifier="modal-main">
  <pmr-modal-header styleModifier="header-style">
    <pmr-heading tagName="h2" [id]="'modal-header'">{{
      modalTitle
      }}</pmr-heading>
  </pmr-modal-header>
  <img src="/assets/images/modal_image.png" alt="Preview Image of Tolerance Table" class="graph-image" />
  <pmr-modal-body>
    <pmr-text-passage>
      <p>{{ modalBodyText }}</p>
    </pmr-text-passage>
  </pmr-modal-body>
  <pmr-modal-footer styleModifier="modal-footer">
    <pmr-button variant="primary" class="try-it-button" text="{{ 'INFO.INTRO_MODAL_BUTTON' | translate }}"
      [text]="buttonText" (click)="closeModal()" [fullWidth]="true"></pmr-button>
  </pmr-modal-footer>
</pmr-modal>
