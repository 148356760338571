import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { ModalService } from '@primer/angular-components';
import { GoogleAnalyticsService } from '../shared/google-analytics-service/google-analytics.service';
import { ConfigsLoaderService } from '../shared/config-loader-service/config-loader-service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  appBuildID: any;
  appBuildNo: string;
  appAuthScope: any;
  appGitNo: any;
  username: string;
  modalId: string = 'user-manual-modal';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: MsalService,
    private modalService: ModalService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private config: ConfigsLoaderService,

  ) {
    this.appBuildID = this.config.appBuildId;
    this.appBuildNo = this.config.appBuildNumber;
    this.appGitNo = this.config.appGitHash;
    this.appAuthScope = this.config.appAuthScope;
  }

  ngOnInit(): void {
    const isAccountPresent =
      this.authService.instance.getAllAccounts().length > 0;
    if (isAccountPresent) {
      this.authService
        .acquireTokenSilent({
          account: this.authService.instance.getActiveAccount(),
          scopes: [this.appAuthScope],
        })
        .subscribe((x) => {
          this.username = x.account.username;
        });
    }
  }

  onLogout() {
    this.authService.logout();
  }

  onOpenFAQModal() {
    this.modalService.open('faq-modal');
  }

  onOpenModalVersion() {
    this.modalService.open(this.modalId);
  }

  onOpenUserManualModal() {
    this.googleAnalyticsService.pushData('user_manual', 'User Manual');
  }
}
