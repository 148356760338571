import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as d3 from 'd3';
import { Subject } from 'rxjs';
import { GraphTooltipData } from './graph-tooltip/graph-tooltip.component';
import { GraphDataElement, Point, GraphData } from '../shared/models/graphModels';
import { GraphSettings, GraphSettingsMode } from '../shared/models/graphSettings';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss'],
})
export class GraphComponent implements OnInit {
  anglesText: string;
  showGraphTooltip = false;
  isClicked = false;
  graphTooltipData: GraphTooltipData;
  graphSettings: GraphSettings;

  @Input() graphData: Subject<GraphData>;
  @Input() graphRemoved: Subject<boolean>;
  @Input() deltaName: string;
  @ViewChild("scatter") scatter: ElementRef;
  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.isClicked = false;
    this.showGraphTooltip = false;
  }
  @HostListener('body:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.isClicked && event.key === 'ArrowRight') {
      event.preventDefault();
      this.onRightArrowDown(this.popupIndex)
    }
    if (this.isClicked && event.key === 'ArrowLeft') {
      event.preventDefault();
      this.onLeftArrowDown(this.popupIndex)
    }
  }

  private margin = 10;
  private width = 840 - this.margin * 3;
  private height = 260 - this.margin * 2;

  private graph: any;
  private xScale: any;
  private yScale: any;
  private dataList: any[] = [];
  popupIndex = 0;
  colorType: string;
  colorTypeClass: string;
  deltaType = '';


  constructor(public translate: TranslateService,) { }

  ngOnInit(): void {
    this.translate.reloadLang(this.translate.currentLang);
    this.translate.get('INFO.ANGLES').subscribe((res) => {
      this.anglesText = res;
    });

    this.graphData.subscribe(graphData => {
      this.loadGraph(graphData);
    });

    this.graphRemoved.subscribe(_ => {
      this.removeGraph();
    });

  }

  removeGraph = () => {
    d3.selectAll('svg.graphWrapper').remove();
    this.emptyList(this.dataList);
  };

  loadGraph(graphData: GraphData) {
    this.graphSettings = graphData.graphSettings;
    this.calculateScale(graphData.elements);
    this.createGraph();
    if (graphData.shouldDrawTolerance && graphData.graphSettings.isToleranceLimitsShown) {
      this.drawTolerance(graphData.elements);
    }

    if (!graphData.shouldDrawTolerance && graphData.graphSettings.isGuidanceLimitsShown) {
      this.drawGuidance(graphData.elements);
    }

    if (graphData.graphSettings.graphMode != GraphSettingsMode.hide_current_position) {
      this.drawCurrentData(graphData.elements);
    }

    if (graphData.graphSettings.isPredictedColorShown) {
      this.drawPostHitData(graphData.elements);
    }

    if (graphData.graphSettings.graphMode == GraphSettingsMode.averages_variation) {
      this.drawUncertaintyBars(graphData.elements);
    }
  }

  private getSymbol(angle: string) {
    switch (angle) {
      case '15': return d3.symbolCircle;
      case '25': return d3.symbolSquare;
      case '45': return d3.symbolDiamond;
      case '75': return d3.symbolStar;
      case '110': return d3.symbolTriangle;
      default: return d3.symbolCircle;
    }
  }

  private getColor(angle: string) {
    switch (angle) {
      case '15': return '#FDA101';
      case '25': return '#0078A9';
      case '45': return '#252F3D';
      case '75': return '#CA1041';
      case '110': return '#80499A';
      default: return '#FDA101';
    }
  }

  private calculateScale(elements: GraphDataElement[]) {
    let max = Math.ceil(Math.max(
      Math.max(...elements.map((o) => Math.abs(o.currentColorData.delta))),
      Math.max(...elements.map((o) => Math.abs(o.postHitData.delta))),
      Math.max(...elements.map((o) => Math.abs(o.toleranceData.guidanceMax))),
      Math.max(...elements.map((o) => Math.abs(o.toleranceData.uncertainityMax))),
      Math.max(...elements.map((o) => Math.abs(o.toleranceData.thresholdMax))),
    ));

    let min = Math.floor(Math.min(
      Math.min(...elements.map((o) => o.currentColorData.delta)),
      Math.min(...elements.map((o) => o.postHitData.delta)),
      Math.min(...elements.map((o) => o.toleranceData.guidanceLow)),
      Math.min(...elements.map((o) => o.toleranceData.uncertainityLow)),
      Math.min(...elements.map((o) => o.toleranceData.thresholdLow)),
    ))

    this.xScale = d3.scaleLinear().domain([0, 120]).range([0, this.width]);
    this.yScale = d3.scaleLinear().domain([min, max]).range([this.height, 0]);
  }

  private createGraph() {
    let svg = d3
      .select(this.scatter.nativeElement)
      .append('svg')
      .attr('class', 'greybg graphWrapper margin-bottom')
      .attr('width', this.width + this.margin * 3)
      .attr('height', this.height + this.margin * 6)

    this.graph = svg
      .append('g')
      .attr('transform', 'translate(60,20)');

    this.graph
      .append('g')
      .attr('transform', 'translate(0,' + this.height + ')')
      .attr('width', '2px')
      .classed('axisGrey', true)
      .call(
        d3
          .axisBottom(this.xScale)
          .tickSize(4)
          .ticks(5)
          .tickValues([15, 25, 45, 75, 110])
          .tickFormat((d, i) => ['15°', '25°', '45°', '75°', '110°'][i])
      );

    this.graph
      .append('text')
      .style('text-anchor', 'middle')
      .attr('x', this.width / 2)
      .attr('y', this.height + 30)
      .text(this.anglesText);

    d3.selectAll('g.axis--x g.tick')
      .append('line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', 0)
      .attr('y2', -this.height)
      .attr('opacity', 0.4);

    this.graph
      .append('g')
      .attr('class', 'axis cursive axis--y')
      .call(d3.axisLeft(this.yScale).tickSize(0).ticks(5))
      .call((g) => g.select('.domain').remove());

    this.graph
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', -28)
      .attr('x', 0 - this.height / 2)
      .style('text-anchor', 'middle');

    d3.selectAll('g.axis--y g.tick')
      .append('line')
      .classed('grid-line', true)
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', this.width)
      .attr('y2', 0);

    // spacing between y axis and label
    d3.selectAll('.tick text') // selects the text within all groups of ticks
      .attr('x', '-5');
    let yGridLine = d3.axisLeft(this.yScale).ticks(5);

    // add y axis grid
    let gridLineGroup = this.graph
      .append('g')
      .attr('class', 'y-axis-gridline')
      .call(yGridLine.tickSize(-this.width).tickFormat(''))
      .call((g) =>
        g
          .selectAll('.tick line')
          .clone()
          .attr('color', (d) => {
            return d === 0 ? '#000' : '#CECECE';
          })
      );

    // remove y axis line
    gridLineGroup.select('.domain').attr('stroke-width', 0);
  }

  private drawCurrentData(elements: GraphDataElement[]) {
    this.drawCurrentLine(elements);
    this.drawCurrentDataSymbols(elements);
  }

  private drawGuidance(elements: GraphDataElement[]) {
    let processedElements = this.processAdditionalPointsForGuidanceIfNeeded(elements);

    this.drawGuidanceArea(processedElements);

    let lowPoints = processedElements.map(x => ({ angle: x.angle, value: x.toleranceData.guidanceLow }));
    this.drawGuidanceLine(lowPoints);

    let maxPoints = processedElements.map(x => ({ angle: x.angle, value: x.toleranceData.guidanceMax }));
    this.drawGuidanceLine(maxPoints);
  }

  private processAdditionalPointsForGuidanceIfNeeded(elements: GraphDataElement[]): GraphDataElement[] {
    let copyElements = [...elements];

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].toleranceData.guidanceLow === null ||
        elements[i].toleranceData.guidanceMax === null) {
        continue;
      }

      if (i == 0) {
        if (this.checkIfValuesAreNull(elements[i + 1].toleranceData.guidanceLow, elements[i + 1].toleranceData.guidanceMax)) {
          this.spliceNewElement(copyElements, elements[i], true);
        }
      }
      else if (i == elements.length - 1) {
        if (this.checkIfValuesAreNull(elements[i - 1].toleranceData.guidanceLow, elements[i - 1].toleranceData.guidanceMax)) {
          this.spliceNewElement(copyElements, elements[i], false);
        }
      }
      else {
        if (this.checkIfValuesAreNull(elements[i - 1].toleranceData.guidanceLow, elements[i - 1].toleranceData.guidanceMax)
          && this.checkIfValuesAreNull(elements[i + 1].toleranceData.guidanceLow, elements[i + 1].toleranceData.guidanceMax)) {
          this.spliceNewElement(copyElements, elements[i], false);
          this.spliceNewElement(copyElements, elements[i], true);
        }
      }
    }

    return copyElements;
  }

  private drawGuidanceArea(elements: GraphDataElement[]) {
    this.graph.append("path")
      .data([elements])
      .attr("fill", "#e9eaec")
      .attr("opacity", 0.6)
      .attr("d", d3.area()
        .defined((d: GraphDataElement) =>
          (d.postHitData.toleranceValue || d.postHitData.toleranceValue == "0")
          && this.isValidValue(d.toleranceData.guidanceLow)
          && this.isValidValue(d.toleranceData.guidanceMax))
        .x((d: GraphDataElement) => this.xScale(d.angle))
        .y0((d: GraphDataElement) => this.yScale(d.toleranceData.guidanceLow))
        .y1((d: GraphDataElement) => this.yScale(d.toleranceData.guidanceMax)))
      .attr('data-cy', function (d: GraphDataElement) {
        return 'guidance-area-' + d[0].deltaType;
      });

  }

  private drawGuidanceLine(points: Point[]) {
    this.graph
      .append('path')
      .data([points])
      .attr('stroke', '#252f3d')
      .attr("stroke-width", 0.5)
      .attr('fill', 'none')
      .attr('d', d3.line()
        .defined((d: Point) => this.isValidValue(d.value))
        .x((d: Point) => this.xScale(d.angle))
        .y((d: Point) => this.yScale(d.value))
      );
  }

  private drawPostHitData(elements: GraphDataElement[]) {
    this.drawPostHitLine(elements);
    this.drawPostHitDataSymbols(elements);
  }

  private drawTolerance(elements: GraphDataElement[]) {
    let processedElements = this.processAdditionalPointsForToleranceIfNeeded(elements);

    this.drawToleranceArea(processedElements);

    let lowPoints = processedElements.map(x => ({ angle: x.angle, value: x.toleranceData.thresholdLow }));
    this.drawToleranceLine(lowPoints);

    let maxPoints = processedElements.map(x => ({ angle: x.angle, value: x.toleranceData.thresholdMax }));
    this.drawToleranceLine(maxPoints);
  }

  private processAdditionalPointsForToleranceIfNeeded(elements: GraphDataElement[]): GraphDataElement[] {
    let copyElements = [...elements];

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].postHitData.toleranceValue === null ||
        elements[i].toleranceData.thresholdLow === null ||
        elements[i].toleranceData.thresholdMax === null) {
        continue;
      }

      if (i == 0) {
        if (this.checkIfValuesAreNull(elements[i + 1].toleranceData.thresholdLow, elements[i + 1].toleranceData.thresholdMax)) {
          this.spliceNewElement(copyElements, elements[i], true);
        }
      }
      else if (i == elements.length - 1) {
        if (this.checkIfValuesAreNull(elements[i - 1].toleranceData.thresholdLow, elements[i - 1].toleranceData.thresholdMax)) {
          this.spliceNewElement(copyElements, elements[i], false);
        }
      }
      else {
        if (this.checkIfValuesAreNull(elements[i - 1].toleranceData.thresholdLow, elements[i - 1].toleranceData.thresholdMax)
          && this.checkIfValuesAreNull(elements[i + 1].toleranceData.thresholdLow, elements[i + 1].toleranceData.thresholdMax)) {
          this.spliceNewElement(copyElements, elements[i], false);
          this.spliceNewElement(copyElements, elements[i], true);
        }
      }
    }

    return copyElements;
  }

  private spliceNewElement(targetCollection: GraphDataElement[], element: GraphDataElement, isPositionAbove: boolean) {
    let copiedElement = Object.assign({}, element);
    let positionInCopyElements = targetCollection.findIndex((obj) => obj.angle === element.angle);

    if (isPositionAbove) {
      copiedElement.angle = (Number(copiedElement.angle) + 1).toString();
      targetCollection.splice(positionInCopyElements, 0, copiedElement);
    } else {
      copiedElement.angle = (Number(copiedElement.angle) - 1).toString();
      targetCollection.splice(positionInCopyElements + 1, 0, copiedElement);
    }
  }

  private checkIfValuesAreNull(value1: any, value2: any): boolean {
    return value1 === null && value2 === null;
  }

  private drawToleranceArea(elements: GraphDataElement[]) {
    this.graph.append("path")
      .data([elements])
      .attr("fill", "#DDEDE1")
      .attr("opacity", 0.6)
      .attr("d", d3.area()
        .defined((d: GraphDataElement) => d.postHitData.toleranceValue || d.postHitData.toleranceValue == "0")
        .x((d: GraphDataElement) => this.xScale(d.angle))
        .y0((d: GraphDataElement) => this.yScale(d.toleranceData.thresholdLow))
        .y1((d: GraphDataElement) => this.yScale(d.toleranceData.thresholdMax)))
      .attr('data-cy', function (d: GraphDataElement) {
        return 'tolerance-area-' + d[0].deltaType;
      });
  }

  private drawToleranceLine(points: Point[]) {
    this.graph
      .append('path')
      .data([points])
      .attr('d', d3.line()
        .defined((d: Point) => this.isValidValue(d.value))
        .x((d: Point) => this.xScale(d.angle))
        .y((d: Point) => this.yScale(d.value))
      )
      .attr('stroke', '#1C883A')
      .attr("stroke-width", 0.5)
      .attr('fill', 'none');
  }

  private drawCurrentLine(elements: GraphDataElement[]) {
    this.graph
      .append('path')
      .data([elements])
      .attr('d', d3.line()
        .defined((d: GraphDataElement) => this.isValidValue(d.currentColorData.delta))
        .x((d: GraphDataElement) => this.xScale(d.angle))
        .y((d: GraphDataElement) => this.yScale(d.currentColorData.delta))
      )
      .attr('stroke', '#92979E')
      .style('stroke-dasharray', '3, 3')
      .attr('stroke-width', 2)
      .attr('fill', 'none');
  }

  private drawPostHitLine(elements: GraphDataElement[]) {
    this.graph
      .append('path')
      .data([elements])
      .attr('d', d3.line()
        .defined((d: GraphDataElement) => this.isValidValue(d.postHitData.delta))
        .x((d: GraphDataElement) => this.xScale(d.angle))
        .y((d: GraphDataElement) => this.yScale(d.postHitData.delta))
      )
      .attr('stroke', '#1C883A')
      .attr('stroke-width', 2)
      .attr('fill', 'none');
  }

  emptyList(list: any) {
    list.length = 0
  }

  private drawCurrentDataSymbols(elements: GraphDataElement[]) {
    let symbol = d3.symbol();
    let list = this.dataList;

    let dots = this.graph
      .selectAll('.dot')
      .data(elements.filter((d: GraphDataElement) => this.isValidValue(d.currentColorData.delta)))
      .enter()
      .append('path');

    dots
      .attr('data-cy', function (d: GraphDataElement) {
        return 'current-average-color-' + d.deltaType + '-angle-' + d.angle;
      })
      .attr('d', symbol.type((d: GraphDataElement) => this.getSymbol(d.angle)))
      .attr(
        'transform',
        (d: GraphDataElement) => 'translate(' + this.xScale(d.angle) + ',' + this.yScale(d?.currentColorData.delta || 0) + ')'
      )
      .attr('r', 5)
      .style('fill', (d: GraphDataElement) => this.getColor(d.angle))
      .on('mouseover', (event) => {
        this.isClicked = false;
        this.popupIndex = list.findIndex(x => x.element == event.currentTarget)
        this.setToolTipsCurrent();

        this.showToolTip(list[this.popupIndex].element, list[this.popupIndex].data, list[this.popupIndex].data[this.deltaType].delta, this.colorType, this.colorTypeClass);

        d3.select(event.currentTarget)
          .raise()
          .style('stroke', 'black');
      })
      .on("mouseout", (event: any) => {
        if (!this.isClicked) {
          this.showGraphTooltip = false
          d3.select(event.currentTarget)
            .style("stroke", "none")
        }
      })
      .on("click", (event: any, d: any) => {
        this.handleClick(event)
      })

    dots.each(function (d: GraphDataElement) {
      list.push(({ element: this, data: d }));
    });
  }

  private drawPostHitDataSymbols(elements: GraphDataElement[]) {
    let symbol = d3.symbol();
    let list = this.dataList;

    let dots = this.graph
      .selectAll('.dot')
      .data(elements.filter((d: GraphDataElement) => this.isValidValue(d.postHitData.delta)))
      .enter()
      .append('path');

    dots
      .attr('d', symbol.type((d: GraphDataElement) => this.getSymbol(d.angle)))
      .attr('data-cy', function (d: GraphDataElement) {
        return 'predicted-color-' + d.deltaType + '-angle-' + d.angle;
      })
      .attr(
        'transform',
        (d: GraphDataElement) => 'translate(' + this.xScale(d.angle) + ',' + this.yScale(d?.postHitData.delta || 0) + ')'
      )
      .attr('r', 5)
      .style('fill', (d: GraphDataElement) => this.getColor(d.angle))
      .on("mouseover", (event: any) => {
        this.isClicked = false;
        this.popupIndex = list.findIndex(x => x.element == event.currentTarget)
        this.setToolTipsPred();

        this.showToolTip(list[this.popupIndex].element, list[this.popupIndex].data, list[this.popupIndex].data[this.deltaType].delta, this.colorType, this.colorTypeClass)

        d3.select(event.currentTarget)
          .raise()
          .style("stroke", "black");
      })
      .on("mouseout", (event: any) => {
        if (!this.isClicked) {
          this.showGraphTooltip = false;
          d3.select(event.currentTarget)
            .style("stroke", "none")
        }
      })
      .on("click", (event: any) => {
        this.handleClick(event);
      })

    dots.each(function (d: GraphDataElement) {
      list.push(({ element: this, data: d }));
    });
  }

  private handleClick($event) {
    $event.stopPropagation()
    this.isClicked = !this.isClicked;
    this.showGraphTooltip = true;
    d3.select(event.currentTarget)
      .style("stroke", "none");
  }

  private onLeftArrowDown(index) {
    let dataChange = this.dataList.length / 2;
    let lastDataPoint = this.dataList.length - 1;

    let newIndex = (index === 0) ? lastDataPoint : (index - 1)

    let isCurrentAndPredicted = () => this.showPredictedColor() && this.showCurrentColor()

    if (isCurrentAndPredicted && (index == 0)) {
      this.setToolTipsPred()
    }
    if (isCurrentAndPredicted && index > dataChange && index <= lastDataPoint) {
      this.setToolTipsPred()
    }
    if (this.showPredictedColor() && !this.showCurrentColor()) {
      this.setToolTipsPred()
    }

    if (isCurrentAndPredicted && index > 0 && index <= dataChange) {
      this.setToolTipsCurrent()
    }
    if (!this.showPredictedColor() && this.showCurrentColor()) {
      this.setToolTipsCurrent()
    }
    this.popupIndex = newIndex
    this.showToolTip(this.dataList[newIndex].element, this.dataList[newIndex].data, this.dataList[newIndex].data[this.deltaType].delta, this.colorType, this.colorTypeClass)
  }

  private onRightArrowDown(index) {
    let dataChange = this.dataList.length / 2;
    let lastDataPoint = this.dataList.length - 1;

    let newIndex = index === lastDataPoint ? 0 : index + 1
    let isCurrentAndPredicted = () => this.showPredictedColor() && this.showCurrentColor()

    dataChange -= 1;

    if (isCurrentAndPredicted && (index == lastDataPoint)) {
      this.setToolTipsCurrent();
    }
    if (isCurrentAndPredicted && index >= dataChange && index < lastDataPoint) {
      this.setToolTipsPred();
    }
    if (isCurrentAndPredicted && index >= 0 && index < dataChange) {
      this.setToolTipsCurrent();
    }
    if (!this.showPredictedColor() && this.showCurrentColor()) {
      this.setToolTipsCurrent()
    }
    if (this.showPredictedColor() && !this.showCurrentColor()) {
      this.setToolTipsPred()
    }

    this.popupIndex = newIndex
    this.showToolTip(this.dataList[newIndex].element, this.dataList[newIndex].data, this.dataList[newIndex].data[this.deltaType].delta, this.colorType, this.colorTypeClass)
  }

  showCurrentColor() {
    return this.graphSettings.graphMode != GraphSettingsMode.hide_current_position;
  }

  showPredictedColor() {
    return this.graphSettings.isPredictedColorShown
  }

  setToolTipsPred() {
    this.colorType = "INFO.PREDICTED_COLOR";
    this.colorTypeClass = "circle predicted";
    this.deltaType = 'postHitData'
  }

  setToolTipsCurrent() {
    this.colorType = "INFO.AVG_CURRENT_COLOR";
    this.colorTypeClass = "circle current";
    this.deltaType = 'currentColorData'
  }

  private showToolTip(element: any, data: GraphDataElement, deltaValue: number, colorType, circleStyle) {
    this.showGraphTooltip = true;
    const rect = element.getBoundingClientRect();

    let rectTop = this.getRectTop(rect, deltaValue, data.toleranceData.isBoxToleranceMethod)
    let rectLeft = this.getRectLeft(rect, data.toleranceData.isBoxToleranceMethod, data.angle, deltaValue)

    this.graphTooltipData = {
      colorType: colorType,
      circleStyle: circleStyle,
      angleData: data,
      top: rectTop,
      left: rectLeft,
      isVariationEnabled: this.graphSettings.graphMode == GraphSettingsMode.averages_variation,
      isToleranceEnabled: this.graphSettings.isToleranceLimitsShown,
      isGuidenceEnabled: this.graphSettings.isGuidanceLimitsShown
    }
  }

  private drawUncertaintyBars(elements: GraphDataElement[]) {
    this.graph.selectAll('.dot')
      .data(elements.filter((d: GraphDataElement) => {
        return this.isValidValue(d.toleranceData.uncertainityLow) &&
          this.isValidValue(d.toleranceData.uncertainityMax);
      }))
      .enter()
      .append("line")
      .attr("x1", (d: GraphDataElement) => this.xScale(d.angle))
      .attr("x2", (d: GraphDataElement) => this.xScale(d.angle))
      .attr("y1", (d: GraphDataElement) => this.yScale(d.toleranceData.uncertainityLow))
      .attr("y2", (d: GraphDataElement) => this.yScale(d.toleranceData.uncertainityMax))
      .attr("stroke", "#000")
      .attr("stroke-width", 0.5)
      .attr("fill", "none")
      .attr('data-cy', function (d: GraphDataElement) {
        return 'uncertainty-' + d.deltaType + '-angle-' + d.angle;
      });

    this.graph.selectAll('.dot')
      .data(elements.filter((d: GraphDataElement) => this.isValidValue(d.toleranceData.uncertainityLow)))
      .enter()
      .append("line")
      .attr("x1", (d: GraphDataElement) => {
        let value = Number(d.angle);
        value -= 1;
        return this.xScale(value);
      })
      .attr("x2", (d: GraphDataElement) => {
        let value = Number(d.angle);
        value += 1;
        return this.xScale(value);
      })
      .attr("y1", (d: GraphDataElement) => this.yScale(d.toleranceData.uncertainityLow))
      .attr("y2", (d: GraphDataElement) => this.yScale(d.toleranceData.uncertainityLow))
      .attr("stroke", "black")
      .attr("stroke-width", 0.5)

    this.graph.selectAll('.dot')
      .data(elements.filter((d: GraphDataElement) => this.isValidValue(d.toleranceData.uncertainityMax)))
      .enter()
      .append("line")
      .attr("x1", (d: GraphDataElement) => {
        let value = Number(d.angle);
        value -= 1;
        return this.xScale(value);
      })
      .attr("x2", (d: GraphDataElement) => {
        let value = Number(d.angle);
        value += 1;
        return this.xScale(value);
      })
      .attr("y1", (d: GraphDataElement) => this.yScale(d.toleranceData.uncertainityMax))
      .attr("y2", (d: GraphDataElement) => this.yScale(d.toleranceData.uncertainityMax))
      .attr("stroke", "black")
      .attr("stroke-width", 0.5)
  }


  private getRectTop(rectData: any, deltaValue: number, isBoxToleranceMethod: boolean) {
    if (deltaValue >= 0) {
      return rectData.top + window.scrollY + 25
    } else if (deltaValue < 0) {
      return (isBoxToleranceMethod ? (rectData.top + window.scrollY - 140) : (rectData.top + window.scrollY - 107))
    }
  }

  private getRectLeft(rectData: any, isBoxToleranceMethod: boolean, angle: string, deltaValue: number) {
    if (angle == "110" && isBoxToleranceMethod) {
      return rectData.left - 200
    } else if (angle == "110" && !isBoxToleranceMethod) {
      return deltaValue >= 0 ? (rectData.left - 135) : (rectData.left - 145)
    } else if (angle != "110") {
      return rectData.left + window.scrollX;
    }
  }

  private isValidValue(value: number) {
    return value || value == 0
  }
}
