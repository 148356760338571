<h3>{{ "INFO.ADJUSTMENT_BUILDER" | translate }}</h3>
<p>{{ "INFO.SLIDER_DESC" | translate }}</p>
<div class="locked_adjustment" *ngIf="!isAdjustmentSaved">
  <pmr-table-row styleModifier="adj-modal-rows">
    <pmr-table-cell label="Column Header 1">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-lock"
        viewBox="0 0 16 16">
        <path
          d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />
      </svg>
    </pmr-table-cell>
    <pmr-table-cell label="Column Header 2">{{
      "INFO.ADJUSTMENT_SAVED_INFO" | translate
      }}</pmr-table-cell>
  </pmr-table-row>
</div>
<div class="reset-button-wrapper">
  <pmr-button [attr.data-cy]="'reset-all-button'" styleModifier="reset-button" text="{{ 'INFO.RESET_ALL' | translate }}"
  icons="assets/icons/arrow-counterclockwise.svg" iconName="arrow-counterclockwise" iconPosition="after"
  (click)="resetAll($event)" [disabled]="isLoadingError || simulatorDataService.areAllConcentrationsUnchanged">
  </pmr-button>
  <pmr-button [attr.data-cy]="'submit-edits-button'" styleModifier="submit-button" text="{{ 'INFO.SUBMIT-EDITS' | translate }}"
  (click)="submitEdits()" variant="primary" [disabled]="simulatorDataService.isSelectedRecommendationSubmitted" >
  </pmr-button>
</div>
