import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SimulatorDataService } from 'src/simulator-data-service/simulator-data.service';

@Component({
  selector: 'app-hit-recommendations',
  templateUrl: './hit-recommendations.component.html',
  styleUrl: './hit-recommendations.component.scss'
})
export class HitRecommendationsComponent implements OnInit {
  recommendedHitIndex: number;
  recommendedHitRegexp = new RegExp(/^\d+/);
  hitRecommendations: any;

  constructor(private simulatorDataService: SimulatorDataService, public translate: TranslateService) { }

  ngOnInit(): void {
    this.simulatorDataService.recommendationV2Response$.subscribe((recommendationV2Response) => {
      this.hitRecommendations = recommendationV2Response.sample.recommendations;
      this.recommendedHitIndex = this.simulatorDataService.selectedHitRecommendation;
    });
  }

  getTooltipText(hit: number) {
    return 'INFO.TOOLTIP_RECOMMENDATION_' + hit.toString();
  }

  isRecommended(idx: number) {
    return this.recommendedHitIndex === (idx);
  }

  onHitRecomendationChange(val: number) {
    this.simulatorDataService.selectedHitRecommendation = val;
    if (this.simulatorDataService.isDynamicAlreadyMadeForHitRecommendation()) {
      this.simulatorDataService.recommendationV2DynamicResponse$.next(this.simulatorDataService.responseRecommendationV2DynamicForHits[this.simulatorDataService.selectedHitRecommendation]);
    } else {
      this.simulatorDataService.recommendationV2Response$.next(this.simulatorDataService.recommendationV2ApiData);
    }
  }
}
