<link rel="stylesheet"
  href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,300,0,0" />
<!-- Google Fonts is open source -->

<div class="toggle-container" *ngIf="isToggleShown">
  <pmr-text-passage styleModifier="toggle-text">
    <p class="toggle-text">{{ "INFO.TOGGLE" | translate }}</p>
    <button [attr.data-cy]="'show-tolerance-toggle'" class="toggle-button" label="toggle-button"
      (click)="toggleTable()">
      <span class="material-symbols-outlined">{{ toggleIcon }}</span>
    </button>
  </pmr-text-passage>
</div>

<div *ngIf="isLoadingError; then isLoadingErrorBlock; else contentBlock"></div>
<ng-template #isLoadingErrorBlock>
  <app-failed-loading *ngIf="showTable" errorMessage="{{
      'INFO.FAILED_TO_LOAD_TOLERANCE_TABLE_COMPONENT' | translate
    }}" class="failed-content"></app-failed-loading>
</ng-template>
<ng-template #contentBlock>
  <div class="tolerance-table-cont" id="toggle-table-container" *ngIf="showTable">
    <div class="above-table">
      <pmr-text-passage styleModifier="last-date">
        <p *ngIf="lastUpdatedinColorPortal !== 'N/A'" class="last-date">
          {{ "INFO.AS_OF" | translate }} {{ lastUpdatedinColorPortal }}&nbsp;
          <span appTooltip [tooltipText]="'INFO.TOOLTIP_TOLERANCE_DATETIME'"><pmr-icon name="help-circle"
              icons="assets/pmr-icons.svg" [inverted]="false" styleModifier="help-icon icon-blue">
            </pmr-icon></span>
        </p>
      </pmr-text-passage>

      <div class="table-key">
        <pmr-text-passage styleModifier="table-key">
          <p class="table-key">{{ "INFO.WHERE" | translate }} &nbsp;</p>
          <p class="table-key key-green">
            {{ "INFO.GREEN" | translate
            }}<pmr-icon name="check-circle-outline" icons="assets/pmr-icons.svg" [inverted]="false"
              styleModifier="key-icon-success"></pmr-icon>
          </p>
          <p class="table-key">&nbsp;{{ "INFO.IS_PASS" | translate }}&nbsp;</p>
          <p class="table-key key-yellow">
            {{ "INFO.YELLOW" | translate
            }}<pmr-icon name="alert-outline" icons="assets/pmr-icons.svg" [inverted]="false"
              styleModifier="key-icon-warning"></pmr-icon>
          </p>
          <p class="table-key">
            &nbsp;{{ "INFO.IS_WARNING" | translate }}&nbsp;
          </p>
          <p class="table-key key-red">
            {{ "INFO.RED" | translate
            }}<pmr-icon name="close-circle-outline" icons="assets/pmr-icons.svg" [inverted]="false"
              styleModifier="key-icon-fail"></pmr-icon>
          </p>
          <p class="table-key">&nbsp;{{ "INFO.IS_FAIL" | translate }}</p>
        </pmr-text-passage>
      </div>
    </div>

    <pmr-inline-status *ngIf="!isToleranceAvailable" [iconName]="iconName" [isSuccess]="isSuccess" [isError]="isError"
      styleModifier="static-tolerance-message">
      <pmr-inline-status-body>
        <pmr-text-passage>{{
          "INFO.NO_TOLERANCEINFO_SAMPLE" | translate
          }}</pmr-text-passage>
      </pmr-inline-status-body>
    </pmr-inline-status>

    <div class="tolerance-table">
      <pmr-table variant="bare" behavior="default" hideCaption="true" inlineColumnLabels="false"
        hideColumnLabels="false" styleModifier="tolerance-table-top">
        <div class="top-table-left">
          <pmr-table-header styleModifier="top-table">
            <pmr-table-row styleModifier="top-table-top-row">
              <pmr-table-header-cell styleModifier="top-row-cell col-1">{{
                "INFO.TYPE" | translate
                }}</pmr-table-header-cell>
              <pmr-table-header-cell styleModifier="top-row-cell col-2">
                {{ "INFO.TOLERANCE_METHOD" | translate }}
                <span appTooltip [tooltipText]="'INFO.TOOLTIP_TOLERANCE_METHOD' | translate">
                  <pmr-icon name="help-circle-outline" icons="assets/pmr-icons.svg" [inverted]="false"
                    styleModifier="help-icon"></pmr-icon>
                </span>
              </pmr-table-header-cell>
            </pmr-table-row>
          </pmr-table-header>

          <pmr-table-body>
            <pmr-table-row styleModifier="top-table-bottom-row">
              <pmr-table-cell styleModifier="bottom-row-cell col-1">{{
                toleranceType
                }}</pmr-table-cell>
              <pmr-table-cell styleModifier="bottom-row-cell col-2">{{
                toleranceMethod
                }}</pmr-table-cell>
            </pmr-table-row>
          </pmr-table-body>
        </div>

        <div class="top-table-right">
          <pmr-table-header styleModifier="top-table">
            <pmr-table-row styleModifier="top-table-top-row">
              <pmr-table-header-cell styleModifier="top-row-cell col-3">mΔE</pmr-table-header-cell>
              <pmr-table-header-cell styleModifier="top-row-cell col-4">mΔE {{ "INFO.PASS_FAIL" | translate
                }}</pmr-table-header-cell>
            </pmr-table-row>
          </pmr-table-header>

          <pmr-table-body>
            <pmr-table-row styleModifier="top-table-bottom-row">
              <pmr-table-cell styleModifier="col-3-bottom">
                <div *ngIf="mDeltaEStatus === ''">{{ mDeltaE }}</div>
                <div *ngIf="mDeltaEStatus === 'Pass'" class="pass-text">
                  {{ mDeltaE }}
                  <pmr-icon name="check-circle-outline" icons="assets/pmr-icons.svg" [inverted]="false"
                    styleModifier="cell-check-success"></pmr-icon>
                </div>
                <div *ngIf="mDeltaEStatus === 'Warning'" class="warning-text">
                  {{ mDeltaE }}
                  <pmr-icon name="alert-outline" icons="assets/pmr-icons.svg" [inverted]="false"
                    styleModifier="cell-check-warning"></pmr-icon>
                </div>
                <div *ngIf="mDeltaEStatus === 'Fail'" class="fail-text">
                  {{ mDeltaE }}
                  <pmr-icon name="close-circle-outline" icons="assets/pmr-icons.svg" [inverted]="false"
                    styleModifier="cell-check-fail"></pmr-icon>
                </div>
              </pmr-table-cell>

              <pmr-table-cell styleModifier="bottom-row-cell col-4">
                <div *ngIf="mDeltaEStatus === ''">
                  {{ "INFO.NOT_APPLICABLE" | translate }}
                </div>
                <div *ngIf="mDeltaEStatus === 'Pass'" class="pass-text">
                  {{ "INFO.PASS" | translate }}
                </div>
                <div *ngIf="mDeltaEStatus === 'Warning'" class="warning-text">
                  {{ "INFO.WARNING" | translate }}
                </div>
                <div *ngIf="mDeltaEStatus === 'Fail'" class="fail-text">
                  {{ "INFO.FAIL" | translate }}
                </div>
              </pmr-table-cell>
            </pmr-table-row>
          </pmr-table-body>
        </div>
      </pmr-table>
      <pmr-table variant="bare" behavior="default" caption="Tolerance Table" hideCaption="true"
        inlineColumnLabels="false" hideColumnLabels="false" styleModifier="tolerance-table-main">
        <pmr-table-header>
          <pmr-table-row styleModifier="bottom-table-top-row">
            <pmr-table-header-cell styleModifier="column-header-1">{{
              "INFO.ANGLES" | translate
              }}</pmr-table-header-cell>
            <pmr-table-header-cell styleModifier="cells column-2">ΔL*</pmr-table-header-cell>
            <pmr-table-header-cell styleModifier="cells">Δa*</pmr-table-header-cell>
            <pmr-table-header-cell styleModifier="cells">Δb*</pmr-table-header-cell>
            <pmr-table-header-cell styleModifier="cells">ΔC*</pmr-table-header-cell>
            <pmr-table-header-cell styleModifier="cells">ΔH*</pmr-table-header-cell>
            <pmr-table-header-cell styleModifier="cells">ΔE ({{ toleranceMethod }})</pmr-table-header-cell>
            <pmr-table-header-cell styleModifier="passfail-cells">{{
              "INFO.PASS_FAIL" | translate
              }}</pmr-table-header-cell>
          </pmr-table-row>
        </pmr-table-header>
        <pmr-table-body *ngIf="angles">
          <pmr-table-row *ngFor="let angle of angles | keyvalue : originalOrder" styleModifier="bottom-table-row">
            <pmr-table-cell styleModifier="row-cell-1">{{ angle.key }}°</pmr-table-cell>
            <pmr-table-cell *ngFor="
                let toleranceAngleValue of angle.value.tolerance.deltas
                  | keyvalue : originalOrder
              " styleModifier="cells">
              <div *ngIf="toleranceAngleValue.value.result === ''">
                {{
                toleranceAngleValue.value.value === ""
                ? "N/A"
                : (toleranceAngleValue.value.value | number : "1.0-2")
                }}
              </div>
              <div *ngIf="toleranceAngleValue.value.result === 'Pass'">
                <span class="pass-text">{{
                  toleranceAngleValue.value.value | number : "1.0-2"
                  }}</span>
                <pmr-icon name="check-circle-outline" icons="assets/pmr-icons.svg" [inverted]="false"
                  styleModifier="cell-check-success"></pmr-icon>
              </div>
              <div *ngIf="toleranceAngleValue.value.result === 'Warning'">
                <span class="warning-text">{{
                  toleranceAngleValue.value.value | number : "1.0-2"
                  }}</span>
                <pmr-icon name="alert-outline" icons="assets/pmr-icons.svg" [inverted]="false"
                  styleModifier="cell-check-warning"></pmr-icon>
              </div>
              <div *ngIf="toleranceAngleValue.value.result === 'Fail'">
                <span class="fail-text">{{
                  toleranceAngleValue.value.value | number : "1.0-2"
                  }}</span>
                <pmr-icon name="close-circle-outline" icons="assets/pmr-icons.svg" [inverted]="false"
                  styleModifier="cell-check-fail"></pmr-icon>
              </div>
            </pmr-table-cell>
            <pmr-table-cell styleModifier="passfail-cells">
              <div *ngIf="angle.value.tolerance.overall_indicator.result === ''">
                {{ "INFO.NOT_APPLICABLE" | translate }}
              </div>
              <div *ngIf="
                  angle.value.tolerance.overall_indicator.result === 'Pass'
                " class="pass-text">
                {{ "INFO.PASS" | translate }}
              </div>
              <div *ngIf="
                  angle.value.tolerance.overall_indicator.result === 'Warning'
                " class="warning-text">
                {{ "INFO.WARNING" | translate }}
              </div>
              <div *ngIf="
                  angle.value.tolerance.overall_indicator.result === 'Fail'
                " class="fail-text">
                {{ "INFO.FAIL" | translate }}
              </div>
            </pmr-table-cell>
          </pmr-table-row>
        </pmr-table-body>
      </pmr-table>
    </div>
  </div>
</ng-template>
