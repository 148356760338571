import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorTranslationService {
  errorMessages: Record<number, string> =  {
    1: "INFO.RECOMMENDATION_SERVICE_UNAVAILABLE_ERROR_MESSAGE",
    2: "INFO.RECOMMENDATION_UNSUCCESSFUL_RESPONSE_CODE_ERROR_MESSAGE",
    3: "INFO.RECOMMENDATION_RESPONSE_ISSUE_ERROR_MESSAGE",
    5: "ERROR.MESSAGE.ML_FAILURE_PROCESSING_REQUEST",
    6: "ERROR.MESSAGE.ML_QUEUE_REQUEST_LIMIT",
    7: "ERROR.MESSAGE.ML_UNAUTHORIZED_ACCESS",
    8: "ERROR.MESSAGE.ML_TIMEOUT",
    9: "ERROR.MESSAGE.ML_FAILED_TO_CONNECT",
    10: "ERROR.MESSAGE.ML_INCOMPLETE_RESPONSE",
    11: "ERROR.MESSAGE.ML_MODEL_NOT_FOUND",
    12: "ERROR.MESSAGE.ML_INTERNAL_ERROR",
    13: "ERROR.MESSAGE.ML_TOLERANCE_DATA_FAIL",
    14: "ERROR.MESSAGE.ML_GUIDANCE_DATA_FAIL",
    15: "ERROR.MESSAGE.ML_UNCERTAINTITY_FAIL",
    16: "ERROR.MESSAGE.ML_UNKNOWN"
  };
  errorTitles: Record<number, string> = {
    5: "ERROR.TITLE.ML_FAILURE_PROCESSING_REQUEST",
    6: "ERROR.TITLE.ML_QUEUE_REQUEST_LIMIT",
    7: "ERROR.TITLE.ML_UNAUTHORIZED_ACCESS",
    8: "ERROR.TITLE.ML_TIMEOUT",
    9: "ERROR.TITLE.ML_FAILED_TO_CONNECT",
    10: "ERROR.TITLE.ML_INCOMPLETE_RESPONSE",
    11: "ERROR.TITLE.ML_MODEL_NOT_FOUND",
    12: "ERROR.TITLE.ML_INTERNAL_ERROR",
    13: "ERROR.TITLE.ML_TOLERANCE_DATA_FAIL",
    14: "ERROR.TITLE.ML_GUIDANCE_DATA_FAIL",
    15: "ERROR.TITLE.ML_UNCERTAINTITY_FAIL",
    16: "ERROR.TITLE.ML_UNKNOWN"
  }
  constructor(private translateService: TranslateService) { }

  translateError(message, internalErrorCode): string {
    const key = this.errorMessages[internalErrorCode];
    if(key){
      return this.translateService.instant(key);
    }else{
      return message??this.translateService.instant("ERROR.MESSAGE.ML_UNKNOWN");
    }
  }
  getTranslatedErrorTitle(internalErrorCode:number, defaultTitle ="Error"):string{
    const key = this.errorTitles[internalErrorCode];
    if(key){
      return this.translateService.instant(key);
    }else{
      return defaultTitle;
    }
  }
}
