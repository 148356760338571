import { Component } from "@angular/core";
import { ModalQueueService } from "../shared/modal-queue-service/ModalQueueService";
import { ModalId } from "../shared/modal-queue-service/ModalId";
import { ModalService } from "@primer/angular-components";
import { BehaviorSubject, Observable, of } from "rxjs";
import { filter, map, switchMap, take } from "rxjs/operators";
@Component({
    template: ''
})
export abstract class BaseQueableModalComponent {
    inSequence: boolean;
    abstract modalState: boolean;
    abstract id: ModalId;
    shouldShow: boolean;
    isReady$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    get hasNext() {
        return this.modalQueueService.hasNext(this.id) && this.inSequence;
    }

    setShouldShow(shouldShow: boolean) {
        this.shouldShow = shouldShow
        this.isReady$.next(true);
        this.modalQueueService.setReady(this.id, this.shouldShow)
    }

    constructor(protected modalQueueService: ModalQueueService, protected modalService: ModalService) {

        this.waitForReady(modalQueueService.reguestedAsNext$)
            .subscribe(
                (requestedModalId) => {
                    if (requestedModalId === this.id) {
                        this.inSequence = true;
                        if (this.shouldShow) {
                            this.openModal();
                        } else {
                            modalQueueService.goToNext(this.id);
                        }
                    }
                }
            );

        this.waitForReady(modalQueueService.requestedAsSingle$)
            .subscribe(
                (requestedModalId) => {
                    if (requestedModalId === this.id) {
                        this.inSequence = false;
                        this.openModal();
                    }
                }
            );
    }

    waitForReady(source$: Observable<ModalId>): Observable<ModalId> {
        return source$.pipe(
            switchMap(event =>
                this.isReady$.pipe(
                    filter(isReady => isReady),
                    take(1),
                    map(() => event)
                )
            )
        );
        
    }

    goToNext() {
        this.closeModal();
        if (this.inSequence) {
            this.modalQueueService.goToNext(this.id);
        }
    }
    openModal() {
        if (!this.modalState) {
            this.modalService.open(this.id);
        }
    }

    closeModal() {
        if (this.modalState) {
            this.modalService.close(this.id);
        }
    }

}