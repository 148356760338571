import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { SimulatorDataService } from 'src/simulator-data-service/simulator-data.service';
import { GraphSettingsService } from '../shared/graph-settings-service/graph-settings.service';
import { ErrorComponentSource } from '../shared/models/errorComponentSource';
import { GraphData, GraphDataElement } from '../shared/models/graphModels';
import { GraphSettings, GraphSettingsMode } from '../shared/models/graphSettings';
import {
  angle as angleInitial,
  postHitDelta as postHitDeltaInitial,
  deltas as deltasInitial,
  currentDelta as currentDeltaInitial,
  sampleInfo,
} from '../shared/models/responseRecommendationV2';
import {
  responseRecommendationV2Dynamic,
  angle as angleDynamic,
  postHitDelta as postHitDeltaDynamic,
  deltas as deltasDynamic
} from '../shared/models/responseRecommendationV2Dynamic';

type DeltaType = 'L' | 'A' | 'B' | 'C' | 'H' | 'E';
type DeltaName = 'ΔL*' | 'Δa*' | 'Δb*' | 'ΔC*' | 'ΔH*' | 'ΔE*';

class Chart {
  constructor(public type: DeltaType, public deltaName: DeltaName) { }
  changed: Subject<GraphData> = new Subject();
  removed: Subject<boolean> = new Subject();
}

@Component({
  selector: 'app-graph-list',
  templateUrl: './graph-list.component.html',
  styleUrls: ['./graph-list.component.scss']
})
export class GraphListComponent implements OnInit {
  charts: Chart[] = [
    new Chart("L", "ΔL*"),
    new Chart("A", 'Δa*'),
    new Chart("B", 'Δb*'),
    // new Chart("C", 'ΔC*'),
    // new Chart("H", 'ΔH*'),
    // new Chart("E", 'ΔE*'),
  ]
  isLoadingError = false;
  isDynamic = false;
  dynamicRecommendation: responseRecommendationV2Dynamic;
  currentGraphSettings: GraphSettings = GraphSettings.createGraphSettings(GraphSettingsMode.averages_variation);

  constructor(private simulatorDataService: SimulatorDataService, private graphSettingsService: GraphSettingsService) { }

  ngOnInit(): void {
    this.simulatorDataService.resetAllResponse$.subscribe({
      next: (_) => {
        this.removeGraphs();
      },
    });

    this.simulatorDataService.recommendationV2Response$.subscribe((initalRecommendation) => {
      this.isLoadingError = false;
      this.isDynamic = false;

      this.removeGraphs();
      this.loadGraphsInitial(initalRecommendation.sample, this.simulatorDataService.selectedHitRecommendation, this.currentGraphSettings);
    });

    this.simulatorDataService.recommendationV2DynamicResponse$.subscribe((dynamicRecommendation) => {
      this.isLoadingError = false;
      this.isDynamic = true;
      this.dynamicRecommendation = dynamicRecommendation;

      this.removeGraphs();
      this.loadGraphsDynamic(this.simulatorDataService.recommendationV2ApiData.sample, this.simulatorDataService.selectedHitRecommendation, dynamicRecommendation, this.currentGraphSettings);
    });

    this.simulatorDataService.errorResponse$.subscribe((source) => {
      if (source === ErrorComponentSource.tolerance_table_and_graphs) {
        this.isLoadingError = true;
      }
    });

    this.graphSettingsService.graphSettingsChanged$.subscribe((settings: GraphSettings) => {
      this.isLoadingError = false;
      this.currentGraphSettings = settings;

      this.removeGraphs();
      if (this.isDynamic) {
        this.loadGraphsDynamic(this.simulatorDataService.recommendationV2ApiData.sample, this.simulatorDataService.selectedHitRecommendation, this.dynamicRecommendation, this.currentGraphSettings);
      } else {
        this.loadGraphsInitial(this.simulatorDataService.recommendationV2ApiData.sample, this.simulatorDataService.selectedHitRecommendation, this.currentGraphSettings);
      }
    });

  }

  removeGraphs = () => {
    this.charts.forEach(x => x.removed.next(true));
  };

  private getInitialData(
    angles: [string, angleInitial][],
    type: DeltaType,
    toleranceMethod: string): GraphDataElement[] {
    const keyCurrentDeltaInitial = ('delta' + type) as keyof (keyof typeof currentDeltaInitial);
    const keyPostHitInitial = ('post_delta' + type) as keyof (keyof typeof postHitDeltaInitial);
    const keyDeltaInitial = type as keyof (keyof typeof deltasInitial);

    let result: GraphDataElement[] = [];
    for (let key in angles) {
      result.push({
        angle: angles[key][0],
        deltaType: type,
        postHitData: {
          delta: angles[key][1].post_hit_delta[keyPostHitInitial],
          toleranceValue: angles[key][1].tolerance.deltas[keyDeltaInitial].value
        },
        currentColorData: {
          delta: angles[key][1].current_delta[keyCurrentDeltaInitial],
        },
        toleranceData: {
          toleranceMethod: toleranceMethod,
          result: angles[key][1].tolerance.deltas[keyDeltaInitial].result,
          thresholdLow: angles[key][1].tolerance.deltas[keyDeltaInitial].threshold_low,
          thresholdMax: angles[key][1].tolerance.deltas[keyDeltaInitial].threshold_max,
          uncertainityLow: angles[key][1].tolerance.deltas[keyDeltaInitial].uncertainity_low,
          uncertainityMax: angles[key][1].tolerance.deltas[keyDeltaInitial].uncertainity_max,
          guidanceLow: angles[key][1].tolerance.deltas[keyDeltaInitial].guidance_low,
          guidanceMax: angles[key][1].tolerance.deltas[keyDeltaInitial].guidance_max,
          isBoxToleranceMethod: this.simulatorDataService.initialSimulatorResponse.initial_recommendation.sample.is_box_tolerance_method
        }
      });
    }
    return result;
  }

  private getDynamicData(
    initialAngles: [string, angleInitial][],
    dynamicAngles: [string, angleDynamic][],
    type: DeltaType,
    toleranceMethod: string): GraphDataElement[] {
    const keyCurrentDeltaInitial = ('delta' + type) as keyof (keyof typeof currentDeltaInitial);
    const keyPostHitDynamic = ('post_delta' + type) as keyof (keyof typeof postHitDeltaDynamic);
    const keyDeltaInitial = type as keyof (keyof typeof deltasInitial);
    const keyDeltaDynamic = type as keyof (keyof typeof deltasDynamic);

    let result: GraphDataElement[] = [];
    for (let key in initialAngles) {
      result.push({
        angle: initialAngles[key][0],
        deltaType: type,
        postHitData: {
          delta: dynamicAngles[key][1].post_hit_delta[keyPostHitDynamic],
          toleranceValue: dynamicAngles[key][1].tolerance.deltas[keyDeltaDynamic].value
        },
        currentColorData: {
          delta: initialAngles[key][1].current_delta[keyCurrentDeltaInitial],
        },
        toleranceData: {
          toleranceMethod: toleranceMethod,
          result: dynamicAngles[key][1].tolerance.deltas[keyDeltaInitial].result,
          thresholdLow: initialAngles[key][1].tolerance.deltas[keyDeltaInitial].threshold_low,
          thresholdMax: initialAngles[key][1].tolerance.deltas[keyDeltaInitial].threshold_max,
          uncertainityLow: initialAngles[key][1].tolerance.deltas[keyDeltaInitial].uncertainity_low,
          uncertainityMax: initialAngles[key][1].tolerance.deltas[keyDeltaInitial].uncertainity_max,
          guidanceLow: initialAngles[key][1].tolerance.deltas[keyDeltaInitial].guidance_low,
          guidanceMax: initialAngles[key][1].tolerance.deltas[keyDeltaInitial].guidance_max,
          isBoxToleranceMethod: this.simulatorDataService.initialSimulatorResponse.initial_recommendation.sample.is_box_tolerance_method
        }
      });
    }
    return result;
  }

  private loadGraphsInitial(initialSample: sampleInfo, selectedHit: number, graphSettings: GraphSettings) {
    let initialRecommendation = initialSample.recommendations[selectedHit];
    let angles = Object.entries(initialRecommendation.angles) as [string, angleInitial][];

    this.charts.forEach(x => x.changed.next(
      {
        elements: this.getInitialData(angles, x.type, initialSample.tolerance_method),
        shouldDrawTolerance: initialSample.is_box_tolerance_method,
        graphSettings: graphSettings
      } as GraphData));
  }

  private loadGraphsDynamic(initialSample: sampleInfo, selectedHit: number,
    dynamicData: responseRecommendationV2Dynamic, graphSettings: GraphSettings) {
    let initialRecommendation = initialSample.recommendations[selectedHit];
    let initialAngles = Object.entries(initialRecommendation.angles) as [string, angleInitial][];
    let dynamicAngles = Object.entries(dynamicData.angles) as [string, angleDynamic][];

    this.charts.forEach(x => x.changed.next(
      {
        elements: this.getDynamicData(initialAngles, dynamicAngles, x.type, initialSample.tolerance_method),
        shouldDrawTolerance: initialSample.is_box_tolerance_method,
        graphSettings: graphSettings
      } as GraphData));
  }
}
