import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BrowserUtils } from "@azure/msal-browser";
import { FailedComponent } from "./failed/failed.component";
import { MsalGuard } from "@azure/msal-angular";
import { LandingPageComponent } from "./landing-page/landing-page.component";
import { ScatterComponent } from "./scatter/scatter.component";
const routes: Routes = [
  {
    path: "login-failed",
    component: FailedComponent,
  },
  {
    path: "",
    component: LandingPageComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "scatter",
    component: ScatterComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "**",
    component: LandingPageComponent,
    canActivate: [MsalGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? "enabledBlocking"
          : "disabled", // Don't perform initial navigation in iframes
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
