import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickableTooltipComponent } from './clickable-tooltip.component';
import { ClickableTooltipDirective } from './clickable-tooltip.directive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { httpTranslateLoaderFactory } from '../app.module';
import { PrimerModule } from '@primer/angular-components';

@NgModule({
  declarations: [ClickableTooltipComponent, ClickableTooltipDirective],
  imports: [
    CommonModule,
    PrimerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [ClickableTooltipDirective],
})
export class ClickableTooltipModule { }
