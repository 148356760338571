import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GraphDataElement } from 'src/app/shared/models/graphModels';

export interface GraphTooltipData {
  circleStyle: string;
  colorType: string;
  angleData: GraphDataElement;
  top: number;
  left: number;
  isVariationEnabled: boolean;
  isToleranceEnabled: boolean;
  isGuidenceEnabled: boolean;
}

@Component({
  selector: 'app-graph-tooltip',
  templateUrl: './graph-tooltip.component.html',
  styleUrls: ['./graph-tooltip.component.scss'],
})
export class GraphTooltipComponent {
  constructor(public translate: TranslateService) { }

  @Input()
  public data: GraphTooltipData;

  @Input()
  public showGraphToolTip: boolean;

  getRoundedValue(num: number) {
    return Math.round(num * 100) / 100;
  }

  setResultClass() {
    let resultClass =
      this.data.angleData.toleranceData.result.toLocaleLowerCase();
    return resultClass;
  }

  setConfirmationIcon() {
    let iconClass = this.setResultClass();
    switch (iconClass) {
      case 'pass':
        return 'check-circle-outline';
      case 'warning':
        return 'alert-outline';
      case 'fail':
        return 'close-circle-outline';
    }
  }

  isGuidenceEnabled() {
    return !this.data.angleData.toleranceData.isBoxToleranceMethod && this.data.isGuidenceEnabled;
  }

  isVariationEnabled() {
    return this.data.angleData.toleranceData.uncertainityLow != null && this.data.isVariationEnabled;
  }

  isToleranceEnabled() {
    return this.data.angleData.toleranceData.isBoxToleranceMethod && this.data.isToleranceEnabled;
  }

  smallTooltip() {
    return (
      !this.isToleranceEnabled() &&
      !this.isGuidenceEnabled() &&
      !this.isVariationEnabled()
    );
  }

  isPredicted() {
    return this.data.circleStyle === 'circle predicted';
  }

  isNotApplicable() {
    if (this.data.angleData.toleranceData.isBoxToleranceMethod && this.isPredicted())
      return this.setResultClass() === '';
  }
}
