import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import {
  responseRecommendationV2,
  initialSimulatorResponse,
} from 'src/app/shared/models/responseRecommendationV2';
import {
  requestRecommendationDynamic,
  recommendation,
} from 'src/app/shared/models/requestRecommendationtDynamic';
import { responseRecommendationV2Dynamic } from 'src/app/shared/models/responseRecommendationV2Dynamic';
import { ErrorComponentSource } from 'src/app/shared/models/errorComponentSource';
import { ConfigsLoaderService } from 'src/app/shared/config-loader-service/config-loader-service';
import { catchError, map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class SimulatorDataService {

  private APP_URL: string = this.config.apiUrl;
  private SIMULATOR_API_URL = this.APP_URL + '/ppg/aiml/colorai/';

  public resetAllResponse$ = new Subject<any>();
  public initialLoad: boolean;
  public formulaResponse$ = new Subject<any>();
  public recommendationV2Response$ = new Subject<responseRecommendationV2>();
  public recommendationV2DynamicResponse$ = new Subject<responseRecommendationV2Dynamic>();
  public errorResponse$ = new Subject<ErrorComponentSource>();
  dynamicResponseForRecommendation: Map<number, boolean> = new Map([
    [0, false],
    [1, false],
  ]);
  recommendationV2ApiData: responseRecommendationV2;
  responseRecommendationV2DynamicForHits = new Array<responseRecommendationV2Dynamic>(2);
  initialRecommendationV2ApiPigmentHitRecommendations: Map<number, any> = new Map([]);
  initialPigmentVals: any[];
  pigments: any[];
  initialSimulatorResponse: initialSimulatorResponse;
  isRecommendationResponseSimilarMap = new Map([
    [0, true],
    [1, true],
  ]);
  recommendations: recommendation[];
  submittedPigmentVals: any;
  selectedHitRecommendation = 0;

  constructor(private config: ConfigsLoaderService, private httpClient: HttpClient) { }

  public get areAllConcentrationsUnchanged() {
    if (this.recommendationV2ApiData) {
      let allUnchanged =true;
      this.initialPigmentVals.forEach((pigment, index) => {
        allUnchanged = allUnchanged && Object.keys(pigment)
          .every(key => pigment[key] === this.recommendationV2ApiData.sample.recommendations[index].hit_wt_pct[key]);
      });
      return allUnchanged;
    }
    return false;
  };

  public get isSelectedRecommendationSubmitted() {
    if(this.submittedPigmentVals){
      return Object.keys(this.submittedPigmentVals[this.selectedHitRecommendation])
        .every(key => this.submittedPigmentVals[this.selectedHitRecommendation][key] ===
          this.recommendationV2ApiData.sample.recommendations[this.selectedHitRecommendation].hit_wt_pct[key]);
    }
    return false;
  }

  setConcentration(pigment: any, value: any) {
    this.recommendationV2ApiData.sample.recommendations[this.selectedHitRecommendation].hit_wt_pct[pigment] = value;
  }

  setDynamicResponseMadeForHitRecommendation(
    selectedHitRecommendation: number,
    responseDynamic: responseRecommendationV2Dynamic
  ) {
    this.responseRecommendationV2DynamicForHits[selectedHitRecommendation] = responseDynamic;
    this.dynamicResponseForRecommendation.set(selectedHitRecommendation, true);
  }

  isDynamicAlreadyMadeForHitRecommendation(): boolean {
    return this.dynamicResponseForRecommendation.get(
      this.selectedHitRecommendation
    );
  }

  resetDynamicResponses() {
    this.dynamicResponseForRecommendation = new Map([
      [0, false],
      [1, false],
    ]);
  }

  getFormulaData(requestGuid: string): Observable<initialSimulatorResponse> {
    return this.httpClient.get<any>(
      this.SIMULATOR_API_URL + 'request/' + `${requestGuid}`
    )
    .pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.error != null) {
          let errorParsered = errorResponse.error.deepClone();
          this.initialSimulatorResponse = errorParsered['formulaInfo'];
        }

        this.errorResponse$.next(
          ErrorComponentSource.tolerance_table_and_graphs
        );

        return throwError(()=>errorResponse);
      }),
      tap((response) => {
        this.initialSimulatorResponse = response.deepClone();
        this.recommendationV2ApiData = this.initialSimulatorResponse.initial_recommendation;
        let recommendationResponse = this.recommendationV2ApiData.sample.recommendations;
        this.initialPigmentVals = recommendationResponse.map(recommendation => recommendation.hit_wt_pct.deepClone());
        this.submittedPigmentVals =this.initialPigmentVals.deepClone();


        let pigments = [];
        Object.entries(this.initialSimulatorResponse.initial_recommendation.sample.max_wt_pct).forEach(
          ([key, value]) => {
            pigments.push({
              pigment_id: key,
              max_hit_percentage: value,
            });
          });

        this.formulaResponse$.next(pigments);
        this.recommendationV2ApiData.sample.recommendations.forEach(
          (recommendation) => {
            let predictionHitIndex = recommendation.hit_no;
            this.initialRecommendationV2ApiPigmentHitRecommendations.set(
              predictionHitIndex,
              Object.assign({}, recommendation.hit_wt_pct)
            );
          }
        );

        this.recommendationV2ApiData.sample.recommendations.forEach(
          (recommendation, index) => {
            recommendation.hit_no = index;
          }
        );

        let recommendations: recommendation[] = [];

        this.initialSimulatorResponse.initial_recommendation.sample.recommendations.forEach(
          (recommendation, index) => {
            recommendations.push({
              hit_no: index,
              is_additive_only: recommendation.is_additive_only,
              hit_wt_pct: recommendation.hit_wt_pct,
              mde: recommendation.mde,
              selected: false
            });
          }
        );
        this.recommendations = recommendations.deepClone();

        this.selectedHitRecommendation = this.recommendationV2ApiData.sample.recommendations.findIndex(
          (recommendation) => recommendation.is_additive_only
        );

        this.recommendationV2Response$.next(this.initialSimulatorResponse.initial_recommendation);

      })
    );
  }

  getRecommendationDynamicData(): Observable<responseRecommendationV2Dynamic> {
    let selectedRecommendation = this.recommendationV2ApiData.sample.recommendations[this.selectedHitRecommendation];

    this.recommendations.forEach(
      (rec) =>
      (rec.selected =
        selectedRecommendation.hit_no === rec.hit_no ? true : false)
    );

    let requestObjV2Dynamic: requestRecommendationDynamic = {
      request_guid: this.initialSimulatorResponse.initial_recommendation.request_guid,
      current_delta: {
        L15: selectedRecommendation.angles[15]?.current_delta?.deltaL,
        L25: selectedRecommendation.angles[25]?.current_delta?.deltaL,
        L45: selectedRecommendation.angles[45]?.current_delta?.deltaL,
        L75: selectedRecommendation.angles[75]?.current_delta?.deltaL,
        L110: selectedRecommendation.angles[110]?.current_delta?.deltaL,
        a15: selectedRecommendation.angles[15]?.current_delta?.deltaA,
        a25: selectedRecommendation.angles[25]?.current_delta?.deltaA,
        a45: selectedRecommendation.angles[45]?.current_delta?.deltaA,
        a75: selectedRecommendation.angles[75]?.current_delta?.deltaA,
        a110: selectedRecommendation.angles[110]?.current_delta?.deltaA,
        b15: selectedRecommendation.angles[15]?.current_delta?.deltaB,
        b25: selectedRecommendation.angles[25]?.current_delta?.deltaB,
        b45: selectedRecommendation.angles[45]?.current_delta?.deltaB,
        b75: selectedRecommendation.angles[75]?.current_delta?.deltaB,
        b110: selectedRecommendation.angles[110]?.current_delta?.deltaB,
      },
      hit_wt_pct: selectedRecommendation.hit_wt_pct,
      product_id: this.initialSimulatorResponse.product_id,
      recommendations: this.recommendations,
    };

    let url = this.SIMULATOR_API_URL + 'recommendation/dynamic';
    return this.httpClient.post<any>(url, requestObjV2Dynamic, {
      observe: 'response',
      responseType: 'text' as 'json',
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        this.errorResponse$.next(ErrorComponentSource.pigment_info_card);
        return throwError(()=>error);
      }),
      map((response) => {
        let resString = JSON.stringify(response.body);
        resString = resString.replace(/\bNaN\b/g, "0");
        return JSON.parse(JSON.parse(resString));
      }),
      tap((response) => {
        response.hit_no = this.selectedHitRecommendation;
        response.selected = true;
        this.submittedPigmentVals[this.selectedHitRecommendation] = this.recommendationV2ApiData.sample.recommendations[this.selectedHitRecommendation].hit_wt_pct.deepClone();
        this.setDynamicResponseMadeForHitRecommendation(this.selectedHitRecommendation, response);
        this.isRecommendationResponseSimilarMap.set(this.selectedHitRecommendation, response.is_similar);
        this.recommendationV2DynamicResponse$.next(response);
      }
    ));
  }
}
