<div *ngIf="isGuidanceVisible" appClickableTooltip [tooltipText]="'INFO.GUIDANCE_TOOLTIP_TEXT' | translate"
  [tooltipStartPostionChildElement]="'span'" [isTooltipButtonVisible]="true"
  [tooltipTitle]="'INFO.GUIDANCE_TOOLTIP_TITLE' | translate"
  [tooltipButtonText]="'INFO.GUIDANCE_TOOLTIP_BUTTON' | translate" (tooltipButtonClick)="openModal()"
  [isVisible]="isToolTipVisible" class="guidance-pill-container">
  <div class="guidance-pill-content">
    <pmr-text-passage styleModifier="header-label">
      <p class="header-label-text">
        {{ "INFO.GUIDANCE_PILL_TEXT" | translate }}
        <span>
          <pmr-icon name="help-circle-outline" icons="assets/pmr-icons.svg" [inverted]="false"
            styleModifier="help-icon">
          </pmr-icon>
        </span>
      </p>
    </pmr-text-passage>
  </div>
</div>