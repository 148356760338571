<div *ngIf="!isErrorDisplaying; then contentBlock"></div>
<ng-template #contentBlock>
  <div class="graph-settings-container">
    <pmr-button [attr.data-cy]="'graph-settings-expander'" variant="bare" type="button" size="default"
      text="{{ 'INFO.GRAPH_SETTINGS' | translate }}" [iconName]="iconName" iconPosition="after"
      (click)="expandCollapseSettings()" styleModifier="graph-settings-main-button">
    </pmr-button>
    <div class="graph-settings-content" [style.display]="isExpanded ? 'grid' : 'none'">
      <div class="graph-settings-content-column">
        <pmr-text-passage styleModifier="header-label">
          <p class="header-label">
            {{ 'INFO.AVG_CURRENT_COLOR' | translate }}
            <span appTooltip [tooltipText]="'INFO.TOOLTIP_AVG_CURRENT_COLOR' | translate">
              <pmr-icon name="help-circle-outline" icons="assets/pmr-icons.svg" [inverted]="false"
                styleModifier="help-icon">
              </pmr-icon></span>
          </p>
        </pmr-text-passage>
        <pmr-select-field [attr.data-cy]="'graph-settings-select-option'" [hideLabel]="true" [items]="items"
          valueField="value" labelField="label" [value]='graphSettingsMode.averages_variation'
          (change)="onGraphActionChange($event.target.value)" styleModifier="select-field-margin">
        </pmr-select-field>
      </div>
      <div class="graph-settings-content-column">
        <pmr-text-passage styleModifier="header-label">
          <p class="header-label"> {{ 'INFO.DISPLAY' | translate }}</p>
        </pmr-text-passage>
        <pmr-checkbox-field [hideLabel]="true">
          <pmr-checkbox-field-item [attr.data-cy]="'graph-settings-predicted-color-checkbox'"
            [(ngModel)]="graphSettings.isPredictedColorShown"
            (change)="onPredictedColorShownChange($event.target.checked)">
            {{ 'INFO.PREDICTED_COLOR' | translate }}
          </pmr-checkbox-field-item>
          <pmr-checkbox-field-item [attr.data-cy]="'graph-settings-is-tolerance-visible-checkbox'"
            *ngIf="graphSettings.graphToleranceGuidanceMode === graphToleranceGuidanceMode.tolerance"
            [(ngModel)]="graphSettings.isToleranceLimitsShown"
            (change)="onToleranceLimitShownChange($event.target.checked)">
            {{ 'INFO.TOLERANCE_LIMITS' | translate }}
          </pmr-checkbox-field-item>
          <pmr-checkbox-field-item [attr.data-cy]="'graph-settings-is-guidance-visible-checkbox'"
            *ngIf="graphSettings.graphToleranceGuidanceMode === graphToleranceGuidanceMode.guidance"
            [(ngModel)]="graphSettings.isGuidanceLimitsShown"
            (change)="onGuidanceLimitShownChange($event.target.checked)">
            {{ 'INFO.GUIDANCE_LIMITS' | translate }}
          </pmr-checkbox-field-item>
        </pmr-checkbox-field>
      </div>
    </div>
  </div>
</ng-template>