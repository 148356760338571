export class GraphSettings {
  graphMode: GraphSettingsMode;
  graphToleranceGuidanceMode: GraphToleranceGuidanceMode =
    GraphToleranceGuidanceMode.none;
  isGuidanceLimitsShown: boolean = true;
  isPredictedColorShown: boolean = true;
  isToleranceLimitsShown: boolean = true;

  public static createGraphSettings(graphMode: GraphSettingsMode): GraphSettings {
    const graphSettings = new GraphSettings();
    graphSettings.graphMode = graphMode;
    return graphSettings;
  }
}

export enum GraphSettingsMode {
  averages_only,
  averages_variation,
  hide_current_position,
}

export enum GraphToleranceGuidanceMode {
  none,
  tolerance,
  guidance,
}
