import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@primer/angular-components';

@Component({
  selector: 'app-guidance-announcement-modal',
  templateUrl: './guidance-announcement-modal.component.html',
  styleUrls: ['./guidance-announcement-modal.component.scss']
})
export class GuidanceAnnouncementModalComponent implements OnInit {

  @Input() modalTitle: string
  @Input() modalBodyText: string

  buttonText: string
  isModalDisplayed: boolean
  public id: string = "guidance-announcement-modal"

  constructor(private modalService: ModalService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.translate.get('INFO.INTRO_MODAL_BUTTON').subscribe((res: string) => {
      this.buttonText = res;
    });
  }

  display() {
    this.isModalDisplayed = true;
  }

  modalOpened(): void {
    this.isModalDisplayed = true;
  }

  modalClosed(): void {
    this.isModalDisplayed = false;
  }

  closeModal(): void {
    this.isModalDisplayed = false;
    this.modalService.close(this.id);
  }
}
