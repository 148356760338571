import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@primer/angular-components';

@Component({
  selector: 'app-announcement-modal',
  templateUrl: './announcement-modal.component.html',
  styleUrls: ['./announcement-modal.component.scss']
})
export class AnnouncementModalComponent implements OnInit {
  @Input() modalTitle: string
  @Input() modalBodyText: string

  expirationDate = new Date(2022, 10, 15)
  buttonText: string
  isModalDisplayed: boolean
  public id: string = "announcement-modal"

  constructor(private modalService: ModalService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.shouldDisplay();
  }

  shouldDisplay() {
    let date = new Date();
    let today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    if (today.getTime() > this.expirationDate.getTime())
      this.isModalDisplayed = false;
    else
      this.isModalDisplayed = true;
  }

  modalOpened(): void {
    this.isModalDisplayed = true;
  }

  modalClosed(): void {
    this.isModalDisplayed = false;
  }
  closeModal(): void {
    this.isModalDisplayed = false;
    this.modalService.close(this.id);
  }
}
