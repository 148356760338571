<div class="failed-loading-area">
  <div class="failed-loading-area-content">
    <div class="icon-alert-area">
      <pmr-icon name="close-circle" color="error"></pmr-icon>
    </div>
    <p class="error-message-paragraph">{{ errorMessage }}</p>
    <div class="try-again-button-wrapper">
      <pmr-button variant="bare" text="{{ 'INFO.TRY_AGAIN' | translate }}" iconName="arrow-counterclockwise"
        iconPositon="after" icons="assets/icons/arrow-counterclockwise.svg" iconPosition="after"
        (click)="tryLoadAgain()">
      </pmr-button>
    </div>
  </div>
</div>