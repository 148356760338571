import { Component } from '@angular/core';

@Component({
  selector: 'app-on-going-development-modal',
  templateUrl: './on-going-development-modal.component.html',
  styleUrls: ['./on-going-development-modal.component.scss']
})
export class OnGoingDevelopmentModalComponent {
  id = 'on-going-development-modal';
  isModalDisplayed: boolean = true;
}
