<pmr-modal [id]="id" [modalState]="isModalDisplayed" (opened)="modalOpened()" (closed)="modalClosed()"
  ariaLabelledBy="app-announcement-modal" styleModifier="modal-main">
  <pmr-modal-header styleModifier="header-style">
    <pmr-heading tagName="h2">
      {{ "INFO.GUIDANCE_MODAL_TITLE" | translate }}
    </pmr-heading>
  </pmr-modal-header>
  <pmr-modal-body>
    <div class="modal-main-body">
      <p class="modal-main-body-text">
        {{ "INFO.GUIDANCE_MODAL_BODY_TEXT" | translate }}
      </p>
    </div>
    <div class="row">
      <div class="column vertical-divider">
        <p class="annotation-header">
          {{ "INFO.GUIDANCE_GRAPH_COLOR_PORTAL_TITLE" | translate }}
        </p>
        <img src="/assets/images/graph_view_in_colorportal.png" alt="Graph view in Color Portal" class="graph-image" />
        <p class="annotation-description">
          {{ "INFO.GUIDANCE_GRAPH_COLOR_PORTAL_TEXT" | translate }}
        </p>
      </div>
      <div class="column">
        <p class="annotation-header">
          {{ "INFO.GUIDANCE_GRAPH_GUIDANCE_LIMITS_TITLE" | translate }}
        </p>
        <img src="/assets/images/graph_with_guidance_limits.png" alt="Graph with guidance limits"
          class="graph-image graph-image-extra-padding-bottom" />
        <p class="annotation-description">
          {{ "INFO.GUIDANCE_GRAPH_GUIDANCE_LIMITS_TEXT" | translate }}
        </p>
      </div>
    </div>
    <pmr-text-passage styleModifier="annotation-footer">
      {{ "INFO.GUIDANCE_MODAL_FOOTER" | translate }}
    </pmr-text-passage>
  </pmr-modal-body>
  <pmr-modal-footer styleModifier="modal-footer">
    <pmr-button variant="primary" [text]="'INFO.GUIDANCE_MODAL_BUTTON_TEXT' | translate" (click)="closeModal()"
      [fullWidth]="true"></pmr-button>
  </pmr-modal-footer>
</pmr-modal>