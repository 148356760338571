import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ConfigsLoaderService {
   private configs?: Configs = (window as any).APP_CONFIG;

   get appBuildId() {return this.configs.appBuildId}
   get appBuildNumber() {return this.configs.appBuildNumber}
   get appGitHash() {return this.configs.appGitHash}
   get appAuthScope() { return this.configs.appAuthScope; }
   get appAuthClientId() { return this.configs.appAuthClientId; }
   get appAuthAuthority() { return this.configs.appAuthAuthority; }
   get appAuthRedicrectUri() {return window.location.origin; }
   get appPostLogoutRedirectUri() { return window.location.origin; }
   get apiUrl() { return this.configs.apiUrl; }
   get appInsightsConnectionsString() { return this.configs.appInsightsConnectionsString}
   get appConfigurationConnectionString() { return this.configs.appConfigurationConnectionString}
   get appConfigurationDeploymentKey() { return this.configs.appConfigurationDeploymentKey}
   get alembaTicketingUrl() { return this.configs.alembaTicketingUrl; }
}

export interface Configs {
    appBuildId: string,
    appBuildNumber: string,
    appGitHash: string,
    appAuthScope: string,
    appAuthClientId: string,
    appAuthAuthority: string,
    appAuthRedirectUri: string
    appPostLogoutRedirectUri: string,
    apiUrl: string,
    appInsightsConnectionsString: string,
    appConfigurationConnectionString: string,
    appConfigurationDeploymentKey: string,
    alembaTicketingUrl: string
}
