import { Component, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-clickable-tooltip',
  templateUrl: './clickable-tooltip.component.html',
  styleUrls: ['./clickable-tooltip.component.scss'],
})
export class ClickableTooltipComponent {
  tooltipText: string = '';
  tooltipTitle: string = '';
  isTooltipButtonVisible: boolean = false;
  tooltipButtonText: string = '';
  left: number = 0;
  top: number = 0;
  isVisible: boolean = false;
  tooltipButtonClick: EventEmitter<any> = new EventEmitter();

  onClick() {
    this.tooltipButtonClick.emit();
    this.isVisible = false;
  }

  onClose() {
    this.isVisible = false;
  }
}
