import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { BaseQueableModalComponent } from '../base-quable-modal/BaseQueableModalComponent';
import { ModalQueueService } from '../shared/modal-queue-service/ModalQueueService';
import { ModalService } from '@primer/angular-components';
import { ModalId } from '../shared/modal-queue-service/ModalId';
import { ReleaseNotes } from './ReleaseNotes';

@Component({
  selector: 'app-release-note-modal',
  templateUrl: './release-note-modal.component.html',
  styleUrls: ['./release-note-modal.component.scss']
})
export class ReleaseNoteModalComponent extends BaseQueableModalComponent implements AfterViewInit {
  id = ModalId.releaseNotesModal;
  modalState: boolean;
  releaseNotes: ReleaseNotes;
  lastReleaseTitleKey = "LastReleaseTitle";

  constructor(protected translateService: TranslateService,
    private http: HttpClient,
    modalQueueService: ModalQueueService,
    modalService: ModalService) {
    super(modalQueueService, modalService)
  }

  ngAfterViewInit(): void {
    this.modalQueueService.addToSequence(this.id)
    this.modalQueueService.startSeqeunce(this.id);
    this.getReleaseNotes()
    .subscribe(
      releaseNotes => {
        this.releaseNotes = releaseNotes;
        this.setShouldShow(localStorage.getItem(this.lastReleaseTitleKey) != this.releaseNotes.TITLE);
      }
    );
  }


  getReleaseNotes(): Observable<ReleaseNotes> {
    const lang = this.translateService.currentLang;
    const url = `/assets/i18n/release-notes/${lang}.json`;
    return this.http.get<ReleaseNotes>(url);
  }

  onNextButton() {
    localStorage.setItem(this.lastReleaseTitleKey,this.releaseNotes.TITLE);
    this.goToNext();
  }
}
