<pmr-footer>
  <pmr-footer-bottom>
    <pmr-layout-container styleModifier="footer-container">
      <pmr-logo styleModifier="pmr-u-margin-bottom-xl" size="small"></pmr-logo>
      <pmr-linelength-container styleModifier="pmr-u-margin-bottom-xl">
        <pmr-text-passage size="small">
          The PPG logo is a registered trademark and Colorful Communities and We
          protect and beautify the world are trademarks of PPG Industries Ohio,
          Inc.<br />© 1999 – 2022 PPG Industries, Inc. All Rights Reserved.
        </pmr-text-passage>
      </pmr-linelength-container>
      <pmr-horizontal-link-list size="small" variant="separators" styleModifier="footer_horizontal-link-list">
        <pmr-horizontal-link-list-item target="_blank" *ngFor="let item of horizontalItems" href="{{ item.href }}"
          isExternal="true">{{ item.text }}</pmr-horizontal-link-list-item>
      </pmr-horizontal-link-list>
    </pmr-layout-container>
  </pmr-footer-bottom>
</pmr-footer>