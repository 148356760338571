<div *ngIf="isVisible" class="tooltip" [style.left]="left + 'px'" [style.top]="top + 'px'">
  <div class="tooltip-title" [attr.data-cy]="'guidance-pill-tooltip-title'">
    {{ tooltipTitle | translate }}
    <pmr-button styleModifier="tooltip-close-button" variant="bare" iconPosition="before" hideText="true"
      iconName="close" (click)="onClose()"></pmr-button>
  </div>

  {{ tooltipText | translate }}

  <div *ngIf="isTooltipButtonVisible" class="tooltip-button-area">
    <pmr-button [attr.data-cy]="'guidance-pill-tooltip-button'" styleModifier="tooltip-button" variant="bare"
      [text]="tooltipButtonText" iconName="arrow-right" iconPosition="after" (click)="onClick()"></pmr-button>
  </div>
</div>