<ng-container *ngIf="data">
  <div class="graph-tooltip" [ngClass]="{
      show: showGraphToolTip,
      hide: !showGraphToolTip,
      'small-tooltip': smallTooltip()
    }" [style.top.px]="data.top" [style.left.px]="data.left">
    <p>
      <span [ngClass]="data.circleStyle"></span>
      {{ data.colorType | translate }}
    </p>

    <div class="tol-value-display">
      <span>{{ "INFO.ANGLE" | translate }}:
        <span class="tooltip-value"> {{ data.angleData.angle }}°</span>
      </span>

      <span class="delta-align">Δ{{ data.angleData.deltaType }}*:
        <span *ngIf="!isPredicted()" class="tooltip-value">
          {{ data.angleData.currentColorData.delta | number : "1.0-2" }}
        </span>

        <span *ngIf="isPredicted()" class="tooltip-value" [ngClass]="setResultClass()">
          {{ data.angleData.postHitData.delta | number : "1.0-2" }}
          <span *ngIf="isToleranceEnabled() || isGuidenceEnabled()">
            <pmr-icon *ngIf="!isNotApplicable()" [name]="setConfirmationIcon()" icons="assets/pmr-icons.svg"
              [inverted]="false" styleModifier="tol-icon">
            </pmr-icon>
            <span *ngIf="isNotApplicable()" class="not-applicable">
              ({{ "INFO.NOT_APPLICABLE" | translate }})
            </span>
          </span>
        </span>
      </span>
    </div>

    <div *ngIf="isVariationEnabled()" class="content-display">
      <hr />
      <p>{{ "INFO.VARIATION" | translate }}</p>
      <div class="content-value-display">
        <span>{{ "INFO.MIN" | translate }}:
          <span *ngIf="
              data.angleData.toleranceData.uncertainityLow === null;
              else hasLowUncertaintyValue
            " class="tooltip-value">
            {{ "INFO.NOT_APPLICABLE" | translate }}
          </span>
          <ng-template #hasLowUncertaintyValue class="tooltip-value">
            {{ getRoundedValue(data.angleData.toleranceData.uncertainityLow) }}
          </ng-template>
        </span>
        <span>{{ "INFO.MAX" | translate }}:
          <span *ngIf="
              data.angleData.toleranceData.uncertainityMax === null;
              else hasMaxUncertainityValue
            " class="tooltip-value">
            {{ "INFO.NOT_APPLICABLE" | translate }}
          </span>
          <ng-template #hasMaxUncertainityValue class="tooltip-value">
            {{
            getRoundedValue(data.angleData.toleranceData.uncertainityMax)
            }}</ng-template>
        </span>
      </div>
    </div>

    <div *ngIf="isGuidenceEnabled()" class="content-display">
      <hr />
      <p>
        <span class="guidance-square"></span>
        {{ "INFO.GUIDANCE_LIMITS" | translate }}
      </p>
      <div class="content-value-display">
        <span>{{ "INFO.MIN" | translate }}:
          <span *ngIf="
              data.angleData.toleranceData.guidanceLow === null;
              else hasLowValue
            " class="tooltip-value">
            {{ "INFO.NOT_APPLICABLE" | translate }}
          </span>
          <ng-template #hasLowValue class="tooltip-value">
            {{ getRoundedValue(data.angleData.toleranceData.guidanceLow) }}
          </ng-template>
        </span>
        <span>{{ "INFO.MAX" | translate }}:
          <span *ngIf="
              data.angleData.toleranceData.guidanceMax === null;
              else hasMaxValue
            " class="tooltip-value">
            {{ "INFO.NOT_APPLICABLE" | translate }}
          </span>
          <ng-template #hasMaxValue class="tooltip-value">
            {{
            getRoundedValue(data.angleData.toleranceData.guidanceMax)
            }}</ng-template>
        </span>
      </div>
    </div>

    <div *ngIf="isToleranceEnabled()" class="content-display">
      <hr />
      <p>
        <span class="tolerance-square"></span>
        {{ "INFO.TOLERANCE_LIMITS" | translate }}
      </p>
      <div class="content-value-display">
        <span>{{ "INFO.MIN" | translate }}:
          <span *ngIf="
              data.angleData.toleranceData.thresholdLow === null;
              else hasLowValue
            " class="tooltip-value">
            {{ "INFO.NOT_APPLICABLE" | translate }}
          </span>
          <ng-template #hasLowValue class="tooltip-value">
            {{ getRoundedValue(data.angleData.toleranceData.thresholdLow) }}
          </ng-template>
        </span>
        <span>{{ "INFO.MAX" | translate }}:
          <span *ngIf="
              data.angleData.toleranceData.thresholdMax === null;
              else hasMaxValue
            " class="tooltip-value">
            {{ "INFO.NOT_APPLICABLE" | translate }}
          </span>
          <ng-template #hasMaxValue class="tooltip-value">
            {{
            getRoundedValue(data.angleData.toleranceData.thresholdMax)
            }}</ng-template>
        </span>
      </div>
    </div>
  </div>
</ng-container>