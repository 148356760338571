<pmr-modal [id]="id"
(closed)="modalState=false"
(opened)="modalState=true"
ariaLabelledBy="app-release-notes-modal"
styleModifier="modal-main">
  <pmr-modal-header>
    <pmr-heading tagName="h2">
      {{ "INFO.RELEASE_NOTES" | translate }}
    </pmr-heading>
  </pmr-modal-header>
  <pmr-modal-body>
    <div class="container__body">
      <div>
        <pmr-heading tagName="h4">
          {{releaseNotes?.TITLE}}
        </pmr-heading>
        <pmr-text-passage styleModifier="item">
          {{releaseNotes?.SUMMARY}}
        </pmr-text-passage>
      </div>
      <div class="container__item" *ngIf="releaseNotes?.FEATURES?.length>0">
        <pmr-heading tagName="h4">
          {{ "INFO.FEATURES" | translate }}:
        </pmr-heading>
        <div class="item" *ngFor="let feature of releaseNotes.FEATURES">
          <pmr-heading tagName="h5">
            {{feature.HEADING}}
          </pmr-heading>
          <pmr-text-passage>
            {{feature.DETAILS}}
          </pmr-text-passage>
        </div>
      </div>
      <div class="container__item" *ngIf="releaseNotes?.BUGFIXES?.length>0">
        <pmr-heading tagName="h4">
          {{ "INFO.BUGFIXES" | translate }}:
        </pmr-heading>
        <div class="item" *ngFor="let bugfix of releaseNotes.BUGFIXES">
          <pmr-heading tagName="h5">
            {{bugfix.HEADING}}
          </pmr-heading>
          <pmr-text-passage>
            {{bugfix.DETAILS}}
          </pmr-text-passage>
        </div>
      </div>
    </div>
  </pmr-modal-body>
  <pmr-modal-footer styleModifier="modal-footer">
    <pmr-button variant="primary" 
    text="{{ (hasNext?
      ('INFO.NEXT' | translate ):
      ('INFO.CONTINUE_TO_SIMULATOR' | translate)
    )}}" 
    iconName="arrow-right"
    iconPosition="{{hasNext?'after':'none'}}"
    [fullWidth]="true"
    (click)="onNextButton()"></pmr-button>
  </pmr-modal-footer>
</pmr-modal>