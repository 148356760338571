// object-extensions.ts
declare global {
  interface Object {
    deepClone<T>(): T;
  }
}

Object.defineProperty(Object.prototype, 'deepClone', {
  value: function<T>(): T {
    return JSON.parse(JSON.stringify(this));
  },
  writable: true,
  configurable: true,
  enumerable: false
});

export {};
