import { Injectable } from '@angular/core';
import { AppConfigurationClient } from '@azure/app-configuration';
import { MsalService } from '@azure/msal-angular';
import { ConfigsLoaderService } from '../config-loader-service/config-loader-service';

@Injectable({
  providedIn: 'root',
})
export default class AppConfigurationService {
  constructor(private authService: MsalService, private configService: ConfigsLoaderService) { }

  async checkAppConfiguration(): Promise<boolean> {
    try {
      const client = new AppConfigurationClient(
        this.configService.appConfigurationConnectionString
      );
      const setting = await client.getConfigurationSetting({
        key: this.configService.appConfigurationDeploymentKey
      });

      const data = JSON.parse(setting.value);
      const users = data.conditions.client_filters[0].parameters.Audience.Users;
      const isEnabled = data.enabled;

      if (isEnabled) {
        let selectedUsers = users as string[];

        return !selectedUsers.includes(
          this.authService.instance.getActiveAccount().username.toLowerCase()
        );
      }
    } catch (e: any) {
      return false;
    }
  }
}
