import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SimulatorDataService } from 'src/simulator-data-service/simulator-data.service';
import { ErrorComponentSource } from '../shared/models/errorComponentSource';
import { ModalService } from '@primer/angular-components';
import AppConfigurationService from '../shared/app-configuration-service/app-configuration.service';
import { Observable, from } from 'rxjs';
import { InlineMessagingService } from '@app-dev/notification-framework';
@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss'],
    providers: [InlineMessagingService]
})
export class LandingPageComponent implements OnInit {
    styleModification: string;
    adjustmentSavedText: string;
    isLoadingError = false;
    buttonText: string;
    saveButtonType = 'primary';
    pigments = [];
    isFeedbackSuccessfulySent = false;
    isDevelopmentOnGoing = false;

    constructor(
        public translateService: TranslateService,
        private simulatorDataService: SimulatorDataService,
        private modalService: ModalService,
        private appConfigurationService: AppConfigurationService,
    ) { }

    ngOnInit(): void {
        const observableAppConfiguration: Observable<boolean> = from(this.appConfigurationService.checkAppConfiguration());

        observableAppConfiguration.subscribe((value: boolean) => {
            this.isDevelopmentOnGoing = value;
        });
        this.translateService.reloadLang(this.translateService.currentLang);

        this.translateService
            .get('INFO.DISABLED_ACTIONBUTTON_AA')
            .subscribe((res: string) => {
                this.adjustmentSavedText = res;
            });

        this.simulatorDataService.errorResponse$.subscribe((source) => {
            if (source === ErrorComponentSource.tolerance_table_and_graphs) {
                this.isLoadingError = true;
            }
        });

        this.simulatorDataService.recommendationV2Response$.subscribe((source) => {
            this.isLoadingError = false;
        });
    }

    openSendAdjustmentModal() {
        this.getPigments();
        this.modalService.open('send-adjustment-modal');
    }

    getPigments() {
      let selectedHitRecommendation = this.simulatorDataService.selectedHitRecommendation;
        let pigmentList = [];

        this.simulatorDataService.pigments.forEach((item) => {
            if (item.pigment_name === this.simulatorDataService?.recommendationV2ApiData?.sample.recommendations[selectedHitRecommendation].hit_wt_pct[item.pigment_name]) {
                pigmentList.push(item);
            }
        });
        this.pigments = pigmentList.filter((item) => item.value > 0);
    }
}
