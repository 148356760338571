<h3>{{ "INFO.HIT_RECOMENDATIONS" | translate }}</h3>
<p>{{ "INFO.HIT_RECOMENDATIONS_DESC" | translate }}</p>
<div class="radio-btns-container">

  <div>
    <ng-container *ngFor="let idx of [0,1]">
      <div class="radio-btn-container" [ngClass]="nudgeColor" [class.recommended]="isRecommended(idx)" >
        <p-radioButton [inputId]="'id'+(idx+1)" [value]="idx" name="name" [(ngModel)]="recommendedHitIndex"
          ariaLabel ="'hit-recommendation-'+(idx+1)" (onClick)="onHitRecomendationChange(idx)"
          class="radio-item"/><span appTooltip [tooltipText]="getTooltipText(idx) | translate">
          <label [for]="'id'+(idx+1)" class="radio-btn-container radio-label"> {{"INFO.HIT_RECOMENDATION" |translate }} {{idx+1}} </label>
        </span>
      </div>
    </ng-container>
  </div>
</div>
