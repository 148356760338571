<div id="header" class="header-main">
  <pmr-band variant="subtle" styleModifier="header-band">
    <pmr-table variant="bare" styleModifier="header-table">
      <pmr-table-header>
        <pmr-table-row styleModifier="simTableRow">
          <pmr-table-header-cell styleModifier="tableHeaderCell">{{'INFO.SCAN_ID' |
            translate}}</pmr-table-header-cell>
          <pmr-table-header-cell styleModifier="tableHeaderCell">{{'INFO.PRODUCT_CODE' |
            translate}}</pmr-table-header-cell>
          <pmr-table-header-cell styleModifier="tableHeaderCell">{{'INFO.SAMPLE_NUMBER_SELECTED' |
            translate}}</pmr-table-header-cell>
        </pmr-table-row>
      </pmr-table-header>
      <pmr-table-body *ngIf="initialSimulatorResponse">
        <pmr-table-row styleModifier="simTableRow">
          <pmr-table-cell [attr.data-cy]="'scanid-header'" styleModifier="tableHeaderRow"
            class="tableCell1">{{initialSimulatorResponse.scan_id}}</pmr-table-cell>
          <pmr-table-cell [attr.data-cy]="'productcode-header'" styleModifier="tableHeaderRow"
            class="tableCell2">{{initialSimulatorResponse.product_id}}</pmr-table-cell>
          <pmr-table-cell [attr.data-cy]="'samplenumber-selected-header'" styleModifier="tableHeaderRow"
            class="tableCell3">{{initialSimulatorResponse.sample_number_selected}}</pmr-table-cell>
        </pmr-table-row>
      </pmr-table-body>
    </pmr-table>
  </pmr-band>
</div>
