import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  horizontalItems: any = [
    { href: 'https://corporate.ppg.com/Legal-Notices-and-Privacy-Policy.aspx', text: 'Legal Notices & Privacy Policies' },
    { href: 'https://www.ppgac.com/privacy-policy/#terms-of-use', text: 'PPG Terms of Use' },
    { href: 'https://www.ppgac.com/privacy-policy', text: 'PPG ArchitecturalCoatings Privacy Policy' },
    { href: 'https://corporate.ppg.com/getmedia/b4acf60d-f36b-4a7c-9741-5d1074b46e47/CA-Transparency-in-Supply-Chain-Act-2017-Revision-(12-7-17-Final).pdf.aspx', text: 'CA Transparency in Supply Chain Disclosure' },
    { href: 'https://corporate.ppg.com/Ethics', text: 'Global Code of Ethics' },
    { href: 'https://corporate.ppg.com/getmedia/ff64db97-1911-443c-b1dc-d4795cb71eb0/Slavery-statements_TISC_industries_2021', text: 'TISC for PPG Industries (UK) Limited' },
    { href: 'https://johnstonestrade.com/getmedia/8024e886-729d-49b8-bd0c-2f644250cab2/gender-pay-gap-report-industries', text: 'PPG Industries UK Ltd 2017 Gender Pay Gap Report' },
  ];
}
