<pmr-modal [id]="id" [modalState]="isModalDisplayed">
  <pmr-modal-header styleModifier="header-style">
    <pmr-heading tagName="h3">{{
      "INFO.DEPLOYMENT_PROCESS" | translate
      }}</pmr-heading>
  </pmr-modal-header>
  <pmr-modal-body>
    <div id="image-container">
      <svg id="svg-image" width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="40" cy="40" r="40" fill="#E9EAEC" />
        <path
          d="M41.582 51.451C41.0828 51.451 40.6039 51.2526 40.251 50.8997L22.1641 32.8127C23.4163 31.351 25.0356 30.213 26.879 29.5416L48.7884 51.451H41.582Z"
          fill="#C4E6FF" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M43.9213 30.7451C43.9213 29.7056 44.764 28.8628 45.8036 28.8628H49.5683C55.8059 28.8628 60.8624 33.9193 60.8624 40.1569C60.8624 42.6977 60.0212 45.0476 58.6027 46.9356C57.9783 47.7667 56.7983 47.9342 55.967 47.3099C55.136 46.6853 54.9684 45.5054 55.5928 44.6742C56.5383 43.4158 57.0977 41.8544 57.0977 40.1569C57.0977 35.9985 53.7266 32.6275 49.5683 32.6275H45.8036C44.764 32.6275 43.9213 31.7847 43.9213 30.7451ZM35.6122 38.2746H28.8624C27.8229 38.2746 26.9801 39.1173 26.9801 40.1569C26.9801 41.1965 27.8229 42.0393 28.8624 42.0393H39.3769L35.6122 38.2746ZM19.4507 40.1569C19.4507 35.2771 22.5454 31.1201 26.8792 29.5416L30.0013 32.6637C26.192 33.037 23.2154 36.2493 23.2154 40.1569C23.2154 44.3152 26.5864 47.6863 30.7448 47.6863H34.5095C35.5491 47.6863 36.3919 48.5291 36.3919 49.5687C36.3919 50.6081 35.5491 51.451 34.5095 51.451H30.7448C24.5072 51.451 19.4507 46.3945 19.4507 40.1569Z"
          fill="#024493" />
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M20.002 20.0023C20.7371 19.2672 21.929 19.2672 22.6641 20.0023L60.3111 57.6494C61.0462 58.3844 61.0462 59.5764 60.3111 60.3114C59.576 61.0465 58.3841 61.0465 57.6491 60.3114L20.002 22.6644C19.2669 21.9293 19.2669 20.7374 20.002 20.0023Z"
          fill="#1E93FF" />
      </svg>
    </div>
    <p class="deployment-title">
      {{ "INFO.DEPLOYMENT_IN_PROGRESS" | translate }}
    </p>
    <p class="deployment-text">{{ "INFO.NEW_VERSION_APPLICATION" | translate }}</p>
  </pmr-modal-body>
</pmr-modal>