import { Injectable } from "@angular/core";
import { ModalId } from "./ModalId";
import { Observable, Subject } from "rxjs";

@Injectable()
export class ModalQueueService {
    private requestedAsNext = new Subject<ModalId>();
    reguestedAsNext$: Observable<ModalId> = this.requestedAsNext.asObservable();

    private requestedAsSingle = new Subject<ModalId>();
    requestedAsSingle$: Observable<ModalId> = this.requestedAsSingle.asObservable();

    private navigationMap: { [modalId: string]: { next?: ModalId, previous?: ModalId, isReady: boolean, shouldShow?: boolean } } = {};


    addToSequence(modalId: ModalId, next?: ModalId, previous?: ModalId) {
        this.navigationMap[modalId] = { next: next, previous: previous, isReady: false };
    }

    setReady(modalId: ModalId, shouldShow: boolean) {
        this.navigationMap[modalId].isReady = true;
        this.navigationMap[modalId].shouldShow = shouldShow;
    }

    startSeqeunce(modalId?: ModalId) {
        modalId = modalId ?? ModalId.releaseNotesModal;
        this.requestedAsNext.next(modalId);
    }

    showAsSingle(modalId: ModalId) {
        this.requestedAsSingle.next(modalId);
    }

    goToNext(modalId: ModalId) {
        const nextModalId = this.getNextModalId(modalId);
        if (nextModalId) {
            this.requestedAsNext.next(nextModalId)
        }
    }

    getNextModalId(modalId: ModalId) {
        return this.navigationMap[modalId]?.next;
    }

    hasNext(modalId: ModalId): boolean {
        const nextModalId = this.getNextModalId(modalId)

        if (this.shouldBeShown(nextModalId)) {
            return true;
        }else{
            const nextModalNavigation = this.navigationMap[nextModalId];
            return this.shouldBeShown(nextModalNavigation?.next)
        }
    }

    private shouldBeShown(modalId: ModalId){
        const modalNavigation = this.navigationMap[modalId];
        return modalNavigation?.isReady && modalNavigation?.shouldShow
    }
}